import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

export const Chip = ({ input, onCloseClick, isDisabled }) => (
    <Tag
        margin={'1 !important'}
        key={input}
        borderRadius="full"
        variant="solid"
        color={'gray.600'}>
        <TagLabel color={'white'}>{input}</TagLabel>
        <TagCloseButton
            className="btn-sm"
            color={'white'}
            isDisabled={isDisabled}
            onClick={() => {
                onCloseClick(input);
            }}
        />
    </Tag>
);

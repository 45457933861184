import { ListItem, UnorderedList, chakra } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

function InstructionMessages({ instructionBody, keywords, darkMode }) {
    const myRef = useRef([]);
    useEffect(() => {
        const hlKeywords = (text, word, tag) => {
            const regex = new RegExp(`(${word})`, 'gi');
            text = text.replace(regex, `<${tag}>${word}</${tag}>`);
            return text;
        };
        if (myRef.current) {
            myRef.current.forEach((ref, index) => {
                if (ref) {
                    let content = instructionBody[index];

                    keywords.forEach((keyword) => {
                        content = hlKeywords(content, keyword, 'strong');
                    });

                    ref.innerHTML = content;
                }
            });
        }
        return () => (myRef.current = []);
    }, [instructionBody, keywords]);

    return (
        <UnorderedList ms={0}>
            {instructionBody.map((v, i) => (
                <ListItem key={`${v} - ${i}`} className="my-3 list-none">
                    <chakra.span
                        color={darkMode ? 'white' : 'black'}
                        ref={(el) => (myRef.current[i] = el)}
                    />
                </ListItem>
            ))}
        </UnorderedList>
    );
}

export default InstructionMessages;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import { Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import ErrorBoundary from '../maintenance/ErrorBoundary';
import { axios } from '../../services/axios';
import { customAutoCompleteStyle } from 'pages/navbar/constant';

export const getOptionLabelName = (option) => option.name || '';

/*
 * This works just like a regular mutli select but it also has search functionality
 */
const MultiSelect = (props) => {
    const {
        label,
        placeholder,
        getOptionLabel,
        setState,
        fieldFetch,
        dependency,
        init,
        loading,
        value,
        groupBy,
        onError,
        staticEnum,
        errorText,
        helperText,
        fixedOptions,
        getOptionSelected,
        chip = {},
        ...rest
    } = props;

    const [options, setOptions] = useState([]);
    const [enumLoading, setEnumLoading] = useState(false);
    const getOptionSelectedID = (option, value) => {
        return option.id === value.id; //on select event
    };

    const { colorScheme } = useSelector((state) => state.settings);

    useEffect(() => {
        if (!staticEnum && (init || dependency)) {
            setEnumLoading(true);
            axios
                .get(fieldFetch)
                .then((res) => {
                    setOptions(res.data);
                    setEnumLoading(false);
                })
                .catch((e) => {
                    onError(e);
                    setEnumLoading(false);
                });
        }
    }, [dependency, init, staticEnum]);

    if (enumLoading) {
        return <Skeleton style={{ marginTop: '2%' }} />;
    }

    //same as auto complete search but the display is a chip and multiple tag is on
    return (
        <ErrorBoundary>
            <Box
                sx={{
                    ...customAutoCompleteStyle(colorScheme),
                    '& .MuiTextField-root': {
                        padding: 0,
                    },
                }}>
                <Autocomplete
                    {...rest}
                    multiple
                    fullWidth
                    value={value}
                    options={staticEnum || options || []}
                    autoComplete={false}
                    getOptionLabel={getOptionLabel || getOptionLabelName} //show dropdown
                    getOptionSelected={getOptionSelected || getOptionSelectedID}
                    loading={loading}
                    groupBy={groupBy}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={
                                        option.name ||
                                        option.location ||
                                        option.id ||
                                        option
                                    } //FOR NAMES TO SHOW UP
                                    {...getTagProps({ index })}
                                    disabled={(fixedOptions || []).some(
                                        (v) => v.id === option.id,
                                    )}
                                    onClick={
                                        chip?.clickable &&
                                        function (e) {
                                            chip.onClick(e, option);
                                        }
                                    }
                                />
                            );
                        })
                    }
                    onChange={(e, v) => {
                        setState(v);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            // below style is for space between the chip tag and label
                            style={{ paddingTop: '5px' }}
                            label={label}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            placeholder={placeholder}
                            error={Boolean(errorText)}
                            helperText={errorText || helperText}
                        />
                    )}
                    popupIcon={<ChevronDownIcon boxSize={5} />}
                />
            </Box>
        </ErrorBoundary>
    );
};

export default MultiSelect;

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import classNames from 'classnames';

import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardOcStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardOcStatusbar';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';

// Assets
import AzureLogo from 'assets/images/Azure.png';
import getService from 'API/Service/getService';

import { MenuItem } from 'components/v4';

export default function ACSDashboard({ service }) {
    const classes = classNames({
        flash: service.changes === 'added',
    });

    // const navigate = useNavigate();
    const [cardStatus, setCardStatus] = useState({
        color: 'gray',
        isAnimated: false,
        hasStripes: false,
        status: '...',
        to: null,
        toText: null,
    });
    const [data, setData] = useState({});
    const handleGetData = () => {
        getService(service?.id)
            .then((res) => {
                setData(res.data);
            })
            .catch((e) => {
                console.log('error get acs', { e });
            });
    };
    useEffect(() => {
        setCardStatus({
            color: 'green',
            isAnimated: false,
            hasStripes: false,
            status: 'Healthy',
        });
    }, []);

    useEffect(() => {
        handleGetData();
    }, [service?.changes]);

    return (
        <DashboardServiceCard
            className={classes}
            dataWalkthroughId={service.name}>
            <DashboardServiceCardOcStatusbar status={cardStatus} />

            <DashboardServiceCardHeader>
                <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                    {service.name}
                </Text>
                <DashboardServiceCardHeaderActionButton>
                    <Link
                        className="bg-transparent !no-underline hover:text-inherit"
                        to={`/services/acsservice/${service.id}/edit`}
                        state={{ serviceId: service.id }}>
                        <MenuItem
                            icon={<EditIcon />}
                            // onClick={() => {
                            //     navigate(`/editacsservice?id=${service.id}`);
                            // }}
                        >
                            <Text marginBottom={'0'}>Edit Service</Text>
                        </MenuItem>
                    </Link>
                </DashboardServiceCardHeaderActionButton>
            </DashboardServiceCardHeader>
            <DashboardServiceCardContent>
                <Box>
                    <Image
                        src={AzureLogo}
                        sx={{ maxWidth: '50px', margin: '0 auto 15px auto' }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDir: 'column',
                        }}>
                        {/* Status */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                            }}>
                            <Text
                                sx={{
                                    fontWeight: '500',
                                }}>
                                Status
                            </Text>
                            <Text>{cardStatus.status}</Text>
                        </Box>

                        {/* Sync Time */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                            }}>
                            <Text
                                sx={{
                                    fontWeight: '500',
                                }}>
                                Max Channel
                            </Text>
                            <Text sx={{ textAlign: 'left' }}>
                                {' '}
                                {data?.maxChannel}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </DashboardServiceCardContent>
        </DashboardServiceCard>
    );
}

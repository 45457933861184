import { chakra, forwardRef } from '@chakra-ui/react';

import { classNames as cx } from '@/utils/classNames';
import { useCardStyles } from './card-context';

/**
 * @typedef {import('@chakra-ui/react').HTMLChakraProps<"div">} CardFooterProps
 * @type React.ForwardRefRenderFunction<HTMLDivElement, CardFooterProps>
 * @returns {JSX.Element}
 */
export const CardFooter = forwardRef(function CardFooter(props, ref) {
    const { className, justify, ...rest } = props;
    const styles = useCardStyles();
    return (
        <chakra.div
            ref={ref}
            className={cx('chakra-card__footer', className)}
            __css={{
                display: 'flex',
                justifyContent: justify,
                ...styles.footer,
            }}
            {...rest}
        />
    );
});

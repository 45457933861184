import { axios } from 'services/axios';

/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/RequestNumberVerification
 *  */
export const postRequestNumberVerification = ({ serviceID, phonenumber }) =>
    axios.post(
        `/Services/TeamsPhoneMobile/${serviceID}/RequestNumberVerification`,
        { phonenumber },
    );
/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/SubmitNumberVerification
 *  */
export const postSubmitNumberVerification = ({
    serviceID,
    phonenumber,
    code,
}) =>
    axios.post(
        `/Services/TeamsPhoneMobile/${serviceID}/SubmitNumberVerification`,
        { phonenumber, code },
    );
/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/ActivateNumbers
 * @param {{serviceID: string, phoneNumbers: string[]}}
 * @returns {Promise<AxiosResponse<string[]>>}
 *  */
export const postActivateNumbers = ({ serviceID, phoneNumbers }) =>
    axios.post(
        `/Services/TeamsPhoneMobile/${serviceID}/ActivateNumbers`,
        phoneNumbers,
    );
/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/ValidateNumbers
 * @param {{serviceID: string, phoneNumbers: string[]}}
 * @returns {Promise<AxiosResponse<string[]>>}
 *  */
export const postValidateNumbers = ({ serviceID, phoneNumbers }) =>
    axios.post(
        `/Services/TeamsPhoneMobile/${serviceID}/ValidateNumbers`,
        phoneNumbers,
    );
/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/DeactivateNumbers
 * @param {{serviceID: string, phoneNumbers: string[]}}
 * @returns {Promise<AxiosResponse<string[]>>}
 *  */
export const postDeactivateNumbers = ({ serviceID, phoneNumbers }) =>
    axios.post(
        `/Services/TeamsPhoneMobile/${serviceID}/DeactivateNumbers`,
        phoneNumbers,
    );
/**
 * @link /Services/TeamsPhoneMobile/{serviceID}/Associations
 * @param {string} serviceID
 *  */
export const getAssociations = ({ serviceID }) =>
    axios.get(`/Services/TeamsPhoneMobile/${serviceID}/Associations`);

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { Box, useDisclosure } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

// DR Card
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DirectRoutingStatus from './DirectRoutingStatus';

// Components
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import ServiceDashboardItemSyncUsers from 'components/v4/Services/ServiceDashboard/ServiceDashboardItemSyncUsers';
import ServiceDashboardTenantId from 'components/v4/Services/ServiceDashboard/ServiceDashboardTenantId';
import { MenuItem } from 'components/v4';
import { IconComponent } from '../IconComponent';

// Util
import { dateStringToDate } from 'utils/utils';

// Assets
import sbcLogo from 'assets/images/sbcaas.png';
import msTeamsLogo from 'assets/images/ms_teams_logo.png';

import path from '@/routes/constants';

import { useServicesDashboard } from 'context/ServicesDashboardContext';
import { DIRECT_ROUTING } from '../constants';
import { useServiceDashboard } from 'hooks/useCustomQuery/useServiceDashboard';
import DashboardServiceCardStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardStatusbar';

export function ServiceDashboardItemLastHealthCheck({ data }) {
    if (!data.lastProcessed)
        return (
            <Box
                sx={{
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                }}>
                <Text
                    sx={{
                        fontWeight: '500',
                        minWidth: '120px',
                        marginBottom: '5px',
                        visibility: 'hidden',
                    }}>
                    {data.lastProcessed && 'Last Health Check'}
                </Text>
                <Text sx={{ visibility: 'hidden' }}>
                    {'Last Health Check,Last Health Check,Last Health Check'}
                </Text>
            </Box>
        );

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                marginBottom: '15px',
            }}>
            <Text
                sx={{
                    fontWeight: '500',
                    minWidth: '120px',
                    marginBottom: '5px',
                }}>
                {data.lastProcessed && 'Last Health Check'}
            </Text>
            <Text>{data.lastProcessed ?? ''}</Text>
        </Box>
    );
}

export default function DirectRoutingCard({
    service,
    serviceWizard,
    companyAppAuth,
}) {
    const modalState = useDisclosure();
    const accountScope = useSelector((state) => state.login.requiredScope);
    const { darkMode } = useSelector((state) => state.settings);

    const [data, setData] = useState(service);
    const [authData, setAuthData] = useState(null);

    const [currentCompanyAppAuth, setCurrentCompanyAppAuth] = useState(null);

    const serviceStatus = useServicesDashboard();
    const cardStatus = serviceStatus[DIRECT_ROUTING];

    const [actionPermissions, setActionPermissions] = useState({
        menuEditServiceScope: 40,
    });

    const {
        data: serviceObj,
        isRefetching,
        isFetching,
        refetch,
    } = useServiceDashboard({
        serviceId: service.id,
        serviceType: DIRECT_ROUTING,
        enabled: !!service.id,
    });

    const handleGrantAccess = async () => {
        if (serviceObj?.company?.selectiveSyncPolicy === 2) {
            if (serviceWizard.msServiceAuth?.id) {
                await serviceWizard.microsoftAuth();
            } else {
                serviceWizard.drFlow();
            }
        } else if (
            serviceObj?.company?.selectiveSyncPolicy === 1 ||
            serviceObj?.company?.selectiveSyncPolicy === 0
        ) {
            await serviceWizard.microsoftAuth();
        }
    };

    useEffect(() => {
        if (companyAppAuth && currentCompanyAppAuth?.id !== companyAppAuth.id) {
            //set MS Service Auth & Service Data.
            setData({
                ...serviceWizard.msServiceAuth,
                ...service,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
            setAuthData(serviceWizard.msServiceAuth);
            setCurrentCompanyAppAuth(companyAppAuth);
        }
    }, [companyAppAuth, service, serviceWizard.msServiceAuth]);

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serviceObj) {
            // Provide Access
            if (
                (serviceObj?.status === 2 && currentCompanyAppAuth === null) ||
                (serviceObj?.status === 2 &&
                    (data.userSync === false || data.graphAuth === false)) ||
                currentCompanyAppAuth?.status === 20
            ) {
                setActionPermissions((p) => ({
                    ...p,
                    ...{
                        menuProvideAccessScope: 20,
                    },
                }));
            } else {
                setActionPermissions((p) => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            p,
                            'menuProvideAccessScope',
                        )
                    ) {
                        delete p.menuProvideAccessScope;
                        return p;
                    }
                    return p;
                });
            }
        }
    }, [currentCompanyAppAuth, companyAppAuth, serviceObj, service]);

    const classes = classNames({
        flash: service.changes === 'added',
    });

    return (
        <>
            <ServiceDashboardTenantId {...modalState} />
            <DashboardServiceCard
                className={classes}
                dataWalkthroughId={service.name}>
                <DashboardServiceCardStatusbar
                    cardStatus={cardStatus}
                    statusDisplay={cardStatus?.statusDisplay}
                />
                <DashboardServiceCardHeader>
                    <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                        {data.name}
                    </Text>
                    <DashboardServiceCardHeaderActionButton
                        id={'direct-routing'}
                        dataWalkthroughId={service.name}>
                        {/* Edit service */}
                        {accountScope >=
                            actionPermissions?.menuEditServiceScope &&
                            cardStatus?.showEdit && (
                                <Link
                                    id="edit-dr-service"
                                    className="bg-transparent !no-underline hover:text-inherit"
                                    to={`${path.SERVICES_DIRECT_ROUTING}/${data.id}/edit`}
                                    state={serviceObj}>
                                    <MenuItem
                                        icon={<EditIcon />}
                                        data-walkthroughid={`/dashboard-services/card/${service.name}/edit-service`}>
                                        <Text marginBottom={'0'}>
                                            Edit Service
                                        </Text>
                                    </MenuItem>
                                </Link>
                            )}

                        {/* Provide Access */}
                        {cardStatus?.grantAccess &&
                            serviceObj?.authPolicy !== 0 && (
                                <MenuItem
                                    id="dr-provide-access"
                                    icon={
                                        <IconComponent
                                            icon="Settings"
                                            color={darkMode ? 'white' : 'black'}
                                        />
                                    }
                                    onClick={handleGrantAccess}
                                    data-walkthroughid={`/dashboard-services/card/${service.name}/provide-access`}>
                                    <Text marginBottom={'0'}>Grant Access</Text>
                                </MenuItem>
                            )}
                    </DashboardServiceCardHeaderActionButton>
                </DashboardServiceCardHeader>
                <DashboardServiceCardContent>
                    <Box>
                        <Image
                            src={
                                service.serviceType === 'SBCaaS'
                                    ? sbcLogo
                                    : msTeamsLogo
                            }
                            sx={{
                                maxWidth: '50px',
                                margin: '0 auto 15px auto',
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDir: 'column',
                            }}>
                            {/* Status */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Status
                                </Text>
                                <DirectRoutingStatus
                                    serviceObj={serviceObj}
                                    authData={authData}
                                    accountScope={accountScope}
                                    handleActions={{
                                        handleGrantAccess,
                                    }}
                                />
                            </Box>

                            {/* Sync */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Sync
                                </Text>
                                <Text sx={{ textAlign: 'left' }}>
                                    <ServiceDashboardItemSyncUsers
                                        data={data}
                                    />
                                </Text>
                            </Box>

                            {/* Last Health Check */}
                            <ServiceDashboardItemLastHealthCheck data={data} />
                        </Box>
                    </Box>
                </DashboardServiceCardContent>
            </DashboardServiceCard>
        </>
    );
}

import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { customColors, config, styles, activeLabelStyles } from './constants';
import { APP_SETTINGS, DEFAULT_COLOR } from 'context/LayoutContext';
import store from './store/store';
import { getBrandColor } from 'utils/getBrandColor';

function getColorScheme() {
    return store.getState().settings?.colorScheme;
}

/** @type import('@chakra-ui/styled-system'.StyleProps)*/
const Input = {
    variants: {
        outline: {
            field: {
                background: '#FFF',
            },
        },
    },
    defaultProps: {
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
        focusBorderColor: 'brand.500',
    },
};
const Select = {
    variants: {
        outline: {
            field: {
                background: '#FFF',
            },
        },
    },
    defaultProps: {
        focusBorderColor: 'brand.500',
    },
};

const Button = {
    baseStyle: {
        borderRadius: '0.25rem',
    },
};

const Form = {
    variants: {
        floatingSelect: {
            container: {
                _focusWithin: {
                    label: {
                        ...activeLabelStyles,
                    },
                },
                'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .react-date-picker + label, .chakra-react-select--has-value + label':
                    {
                        ...activeLabelStyles,
                    },
                label: {
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    position: 'absolute',
                    backgroundColor: 'white',
                    pointerEvents: 'none',
                    mx: 3,
                    px: 1,
                    my: 2,
                    transformOrigin: 'left top',
                },
            },
        },
    },
};

const FormLabel = {
    variants: {
        floating: {
            fontSize: 'sm',
            position: 'absolute',
            transition: 'all 0.2s ease-in 0s',
            top: '-27px',
            left: 0,
        },
    },
};

const Card = {
    variants: {
        rounded: {
            padding: 8,
            borderRadius: 'xl',
            boxShadow: 'xl',
        },
        smooth: {
            padding: 6,
            borderRadius: 'base',
            boxShadow: 'md',
        },
    },
};

const Container = {
    baseStyle: {
        maxWidth: 'none',
    },
};

const Tabs = {
    baseStyle: {
        tab: {
            // minW: '160px',
            // _focus: {
            //     boxShadow: 'none',
            // },
            // _selected: {
            //     borderBottom: '2px solid #03a9f4!important',
            //     transition:
            //         'border-bottom 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            //     opacity: '1 !important',
            // },
            // _active: {
            //     position: 'relative',
            //     overflow: 'hidden',
            //     _before: {
            //         content: '""',
            //         position: 'absolute',
            //         backgroundColor: 'rgba(3, 169, 244, 0.5)',
            //         top: '50%',
            //         left: '50%',
            //         width: '120%',
            //         height: '120%',
            //         transform: 'translate(-50%, -50%)',
            //         borderRadius: '50%',
            //         animation: 'ripple .5s linear infinite',
            //     },
            //     '@keyframes ripple': {
            //         '0%': {
            //             width: 0,
            //             height: 0,
            //             opacity: 0.5,
            //         },
            //         '100%': {
            //             width: '500px',
            //             height: '500px',
            //             opacity: 0,
            //         },
            //     },
            // },
            _dark: {
                textColor: 'white',
            },
            _light: {
                textColor: 'inherit',
            },
        },
        // root: {
        //     _focus: {
        //         boxShadow: 'none',
        //     },
        //     fontFamily: 'Roboto, Helvetica, Arial',
        //     fontWeight: '500',
        // },
        // tablist: {
        //     border: 'none !important',
        // },
    },
    defaultProps: {
        colorScheme: 'brand',
    },
};

const globalColor = JSON.parse(
    localStorage.getItem(APP_SETTINGS) ||
        `{ "colorScheme": "${DEFAULT_COLOR}" }`,
).colorScheme;
const brandColor = getBrandColor(globalColor);

export default extendTheme(
    {
        colors: {
            brand: brandColor,
            ...customColors,
        },
        shadows: {
            outline: '0 0 0 1px var(--chakra-colors-brand-500)',
        },
        components: {
            Input,
            Select,
            Form,
            FormLabel,
            Button,
            Card,
            Container,
            Tabs,
        },
        styles,
        config,
    },
    withDefaultColorScheme({
        colorScheme: 'brand',
        commponents: ['Input', 'Select', 'Tabs'],
    }),
    withDefaultColorScheme({
        colorScheme: 'gray',
        components: ['Progress'],
    }),
);

export const tcapDarkShadow = '0 0 0 1px #6a696a';

export const ChakraDatePickerDarkModeStyles = {
    dateNavBtnProps: {
        colorScheme: 'brand',
        color: 'brand.400',
        variant: 'outline',
        _hover: {
            background: 'brand.200',
        },
    },
    inputProps: {
        borderColor: 'dark.tcap-borderGray',
    },
    dayOfMonthBtnProps: {
        defaultBtnProps: {
            // borderColor: 'red.300',
            borderRadius: '50%',
            width: '2rem',
            _hover: {
                background: 'brand.400',
            },
        },
        isInRangeBtnProps: {
            color: 'brand.800',
            background: 'brand.200',
            borderColor: 'blue.300',
            opacity: '0.9',
        },
        selectedBtnProps: {
            background: 'brand.400',
            borderColor: 'blue.300',
            // color: 'blue.600',
        },
        todayBtnProps: {
            // background: 'teal.200',
            // color: 'teal.700',
        },
    },
    popoverCompProps: {
        popoverContentProps: {
            background: 'dark.bgDarkGray',
            color: 'white',
        },
    },
};

export const ChakraDatePickerStyles = {
    dateNavBtnProps: {
        colorScheme: 'brand',
        variant: 'outline',
    },
    dayOfMonthBtnProps: {
        defaultBtnProps: {
            // borderColor: 'red.300',
            borderRadius: '50%',
            width: '2rem',
            _hover: {
                background: 'brand.400',
            },
        },
        isInRangeBtnProps: {
            color: 'brand.800',
            background: 'brand.200',
            borderColor: 'blue.300',
            opacity: '0.9',
        },
        selectedBtnProps: {
            background: 'brand.400',
            borderColor: 'blue.300',
            // color: 'blue.600',
        },
        todayBtnProps: {
            // background: 'teal.200',
            // color: 'teal.700',
        },
    },
};

export const floatingLabelStyles = (darkMode) => ({
    '[data-peer="true"]:placeholder-shown ~ label': {
        fontSize: 'md',
        top: 2,
        left: 4,
        fontWeight: 'normal',
        color: darkMode ? 'gray.500' : 'gray.400',
    },
    '[data-peer="true"]:focus ~ label': {
        fontSize: 'sm',
        fontWeight: 'medium',
        top: '-27px',
        left: 0,
    },
    '[data-peer="true"] ~ label': {
        color: darkMode ? 'white' : 'black',
    },
});

export const floatingSelectStyles = (darkMode) => {
    const colorScheme = getColorScheme();
    return {
        'label.chakra-form__label': {
            bg: darkMode ? 'dark.bgDark' : '#F5F5F5',
            color: darkMode
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(0, 0, 0, 0.54)',
            fontWeight: '400',
            letterSpacing: '0.00938em',
            '&[data-focus]': {
                color: `${colorScheme}.500`,
            },
        },
        'div.chakra-react-select__control': {
            bg: darkMode ? 'dark.bgDark' : '#F5F5F5',
        },
    };
};

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import ChipInput from 'material-ui-chip-input';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

import { onModalError } from '../../../../components/tables/TableAndModal';
import config from '../../../../config.json';
import ActionHeaders from './ActionHeaders';
import { axios } from '../../../../services/axios';
import { addSpacing } from '../../../../utils/utils';
import {
    DeleteBox,
    TextEditor,
    LoadingFieldDropdown,
} from '../../../../components';
import MultiSelect from '../../../../components/presentational/FillOnClickMultiselect';

import walkthroughIds from '../walkthroughIds';

/*
 * Edit Component from the Triggers Field
 */
export default function TriggersEdit() {
    const dispatch = useDispatch();
    const [cursorPosition, setCursorPosition] = useState(0); //cursor tracker.
    const [chipInputRef, setChipInputRef] = useState(null);
    const [fields, setFields] = useState([]);

    // partner and wholesaler frm state
    const { currentWholesaler, currentPartner } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { editMode } = walkthroughIds;

    // modal state
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // scope from state
    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    //
    const setMultiSelectState = (payload) => {
        if (modalState?.activeTextareaID?.startsWith('webHookHeader')) {
            const newPair = {
                id: modalState?.activeRowID,
                key: modalState?.key,
                value: modalState?.value,
                ...payload,
            };
            // eslint-disable-next-line no-unsafe-optional-chaining
            var newTable = [...modalState?.actionHeaders];
            newTable.splice(modalState?.activeRowIndex, 1, newPair);
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    actionHeaders: newTable,
                },
            });
            return;
        }

        if (modalState?.activeTextareaID?.startsWith('emailAddressField')) {
            const { emailAddress } = payload;
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    emailAddressTmp: emailAddress,
                },
            });
            return;
        }
        setActionState(payload);
    };

    //
    const setActionState = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                action: {
                    ...modalState?.action,
                    ...payload,
                },
            },
        });
    };

    //
    const triggerType = () => {
        return config.triggers.type.slice(
            modalState?.event?.requiredScope
                ? modalState?.event?.requiredScope / 20 - 1
                : 0,
            requiredScope / 20,
        );
    };

    //
    const idDropdown = () => {
        if (
            (requiredScope === 60 &&
                modalState?.triggerType === 'Wholesaler') ||
            (requiredScope === 40 && modalState?.triggerType === 'Partner') ||
            (requiredScope === 20 && modalState?.triggerType === 'Company')
        ) {
            return;
        }
        return (
            <Col>
                {status === 'loading' ? (
                    <Skeleton
                        height={66}
                        width="100%"
                        style={{ marginTop: '-1.5%' }}
                    />
                ) : (
                    <>
                        {modalState?.triggerType === 'Wholesaler' && (
                            <LoadingFieldDropdown
                                searchable
                                fieldFetch="/wholesalers/"
                                fieldName="Wholesaler"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                noEmptyOption
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            wholesalerID: v,
                                        },
                                    });
                                }}
                                errorText={
                                    !modalState?.wholesalerID &&
                                    'Field is required.'
                                }
                                fieldValue={modalState?.wholesalerID}
                                dataWalkthroughid={`${editMode.ufEntitySelect}/${modalState?.triggerType}`}
                            />
                        )}
                        {modalState?.triggerType === 'Partner' && (
                            <LoadingFieldDropdown
                                searchable
                                onFieldFetch={() => {
                                    return axios.get(
                                        `/partners/${currentWholesaler}`,
                                    );
                                }}
                                fieldName="Partner"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                noEmptyOption
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            partnerID: v,
                                        },
                                    });
                                }}
                                errorText={
                                    !modalState?.partnerID &&
                                    'Field is required.'
                                }
                                fieldValue={modalState?.partnerID}
                                dataWalkthroughid={`${editMode.ufEntitySelect}/${modalState?.triggerType}`}
                            />
                        )}
                        {modalState?.triggerType === 'Company' && (
                            <LoadingFieldDropdown
                                searchable
                                staticEnum={companies}
                                fieldName="Company"
                                dropDownValue="id"
                                onError={onModalError}
                                displayField="name"
                                noEmptyOption
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            companyID: v,
                                        },
                                    });
                                }}
                                errorText={
                                    !modalState?.companyID &&
                                    'Field is required.'
                                }
                                fieldValue={modalState?.companyID}
                                dataWalkthroughid={`${editMode.ufEntitySelect}/${modalState?.triggerType}`}
                            />
                        )}
                    </>
                )}
            </Col>
        );
    };

    // get events drop down data when modal is opened
    useEffect(() => {
        axios
            .get('/trigger/events/')
            .then((res) => {
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: { eventEnum: [...res.data] },
                });
            })
            .catch(() => {});
    }, [modalData?.id]);

    // initiate fields in modal so its not null
    useEffect(() => {
        if (!modalState?.actionHeaders) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    actionHeaders:
                        Object.keys(modalState?.action?.headers ?? {}).length >=
                        1
                            ? Object.entries(modalState?.action?.headers)
                                  .map(([key, value]) => ({
                                      key,
                                      value,
                                  }))
                                  .sort((l, r) => l?.key?.localeCompare(r?.key))
                                  .map(({ key, value }, index) => ({
                                      key,
                                      value,
                                      index,
                                  }))
                            : [{ id: 0, key: '', value: '' }],
                },
            });
        }
    }, [modalState?.actionHeaders]);

    // set email if null
    useEffect(() => {
        if (!Array.isArray(modalState?.action?.emailAddress)) {
            setActionState({
                emailAddress:
                    modalState?.action?.emailAddress
                        ?.trim()
                        .split(',')
                        .filter((v) => v.length > 0) ?? [],
            });
        }
    }, [modalState?.action?.emailAddress]);

    // when you select a new event, change Fields input
    useEffect(() => {
        if (modalState?.eventID && modalState?.eventEnum) {
            const newEvent = modalState?.eventEnum?.find(
                ({ id }) => id === modalState?.eventID,
            );
            const newFields = newEvent?.fields?.split(',').map((v) => ({
                id: '[' + v.trim() + ']',
                name: addSpacing(v.trim()),
            }));
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: { event: newEvent },
            });
            setFields(newFields);
        }
    }, [modalState?.eventID, modalState?.eventEnum]);

    const { data: companies, status } = useQuery({
        queryKey: ['triggerCompanies', currentWholesaler, currentPartner],
        // enabled: requiredScope > 20 && modalState?.triggerType === 'Company',
        queryFn: async () => {
            const res = await axios.get('/companies/list', {
                params: {
                    wholesalerID: currentWholesaler,
                    partnerID: currentPartner,
                },
                headers: {
                    'X-RequestScope': 40,
                },
            });

            if (res.status !== 200) {
                throw new Error('Error fetching companies');
            }

            return res.data;
        },
        refetchOnWindowFocus: false,
    });

    return (
        <>
            <h3
                style={{
                    fontWeight: 500,
                    fontSize: '1.75rem',
                    marginBottom: '0.5rem',
                }}>
                Trigger
            </h3>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        value={modalState?.name}
                        label="Name"
                        fullWidth
                        error={Boolean(modalStateError?.name)}
                        helperText={modalStateError?.name}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    name: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={editMode.ufNameInput}
                    />
                </Col>
                {modalMode !== 'Add' && (
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={modalState?.enabled}
                                    onChange={() => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                enabled: !modalState?.enabled,
                                            },
                                        });
                                    }}
                                    data-walkthroughid={
                                        editMode.ufEnabledCheckbox
                                    }
                                />
                            }
                            label="Enabled"
                        />
                    </Col>
                )}
            </Row>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <LoadingFieldDropdown
                        fieldName="Type"
                        fieldValue={modalState?.triggerType}
                        staticEnum={triggerType()}
                        onChange={(v) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    triggerType: v,
                                },
                            });
                        }}
                        errorText={modalStateError?.triggerType}
                        noEmptyOption
                        dataWalkthroughid={editMode.ufTypeSelect}
                    />
                </Col>
                {idDropdown()}
            </Row>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        value={modalState?.description}
                        label="Description"
                        fullWidth
                        error={Boolean(modalStateError?.description)}
                        helperText={modalStateError?.description}
                        multiline
                        rows={3}
                        rowsMax={3}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    description: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={editMode.ufDescriptionTextarea}
                    />
                </Col>
            </Row>
            {modalState?.triggerType && (
                <>
                    <h3
                        style={{
                            fontWeight: 500,
                            fontSize: '1.75rem',
                            marginBottom: '0.5rem',
                        }}>
                        {' '}
                        Event{' '}
                    </h3>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <LoadingFieldDropdown
                                fieldValue={modalState?.eventID}
                                searchable
                                errorText={modalStateError?.eventID}
                                noEmptyOption
                                staticEnum={modalState?.eventEnum?.filter(
                                    ({ requiredScope: requiredScopeMenu }) => {
                                        switch (modalState?.triggerType) {
                                            case 'Company':
                                                return requiredScopeMenu <= 20;
                                            case 'Partner':
                                                return requiredScopeMenu <= 40;
                                            case 'Wholesaler':
                                                return requiredScopeMenu <= 60;
                                            default:
                                                return true;
                                        }
                                    },
                                )}
                                fieldName="Name"
                                dropDownValue={'id'}
                                displayField="name"
                                onDisplayDropdown={({ name, description }) =>
                                    name &&
                                    `${addSpacing(name)} - ${description}  `
                                }
                                onError={() => {}}
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: { eventID: v },
                                    });
                                }}
                                dataWalkthroughid={editMode.ufEventSelect}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {modalState?.eventID && (
                <>
                    <h3
                        style={{
                            fontWeight: 500,
                            fontSize: '1.75rem',
                            marginBottom: '0.5rem',
                        }}>
                        {' '}
                        Action{' '}
                    </h3>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <LoadingFieldDropdown
                                fieldName="Action Type"
                                fieldValue={modalState?.action?.actionType}
                                staticEnum={config.triggers.actionType}
                                onChange={(v) => {
                                    setActionState({ actionType: v });
                                }}
                                errorText={modalStateError?.action?.actionType}
                                noEmptyOption
                                dataWalkthroughid={editMode.ufActionTypeSelect}
                            />{' '}
                        </Col>
                    </Row>
                </>
            )}
            {modalState?.eventID &&
                modalState?.action?.actionType === 'Email' && (
                    <>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <ChipInput
                                    id="emailAddressField"
                                    value={
                                        Array.isArray(
                                            modalState?.action?.emailAddress,
                                        )
                                            ? modalState?.action?.emailAddress
                                            : []
                                    }
                                    variant="outlined"
                                    fullWidth
                                    onSelect={() => {
                                        setCursorPosition(
                                            chipInputRef.selectionStart,
                                        );
                                    }}
                                    onFocus={() => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                activeTextareaID:
                                                    'emailAddressField',
                                                activeTextareaField:
                                                    'emailAddress',
                                            },
                                        });
                                    }}
                                    label="Email Addresses"
                                    inputRef={setChipInputRef}
                                    blurBehavior="ignore"
                                    delayBeforeAdd
                                    newChipKeys={[' ', 'Enter']}
                                    newChipKeyCodes={[13, 32]}
                                    onAdd={(chip) => {
                                        if (
                                            Array.isArray(
                                                modalState?.action
                                                    ?.emailAddress,
                                            )
                                        ) {
                                            setActionState({
                                                emailAddress:
                                                    modalState?.action?.emailAddress.concat(
                                                        chip
                                                            ?.split(',')
                                                            .map((v) =>
                                                                v.trim(),
                                                            )
                                                            .filter(
                                                                (v) =>
                                                                    v.length >
                                                                    0,
                                                            ),
                                                    ),
                                            });
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    emailAddressTmp: '',
                                                },
                                            });
                                            return;
                                        }
                                        setActionState({
                                            emailAddress: chip
                                                ?.split(',')
                                                .map((v) => v.trim())
                                                .filter((v) => v.length > 0),
                                        });
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                emailAddressTmp: '',
                                            },
                                        });
                                    }}
                                    onDelete={(chip, index) => {
                                        var newAddress = [
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            ...modalState?.action?.emailAddress,
                                        ];
                                        newAddress.splice(index, 1);

                                        setActionState({
                                            emailAddress: newAddress,
                                        });
                                    }}
                                    inputValue={modalState?.emailAddressTmp}
                                    InputProps={{
                                        onBlur: (e) => {
                                            if (
                                                Array.isArray(
                                                    modalState?.action
                                                        ?.emailAddress,
                                                )
                                            ) {
                                                setActionState({
                                                    emailAddress:
                                                        modalState?.action?.emailAddress.concat(
                                                            e.target.value
                                                                ?.split(',')
                                                                .map((v) =>
                                                                    v.trim(),
                                                                )
                                                                .filter(
                                                                    (v) =>
                                                                        v.length >
                                                                        0,
                                                                ),
                                                        ),
                                                });
                                                dispatch({
                                                    type: 'CHANGE_MODAL_STATE',
                                                    payload: {
                                                        emailAddressTmp: '',
                                                    },
                                                });
                                                return;
                                            }
                                            setActionState({
                                                emailAddress: e.target.value
                                                    ?.split(',')
                                                    .map((v) => v.trim())
                                                    .filter(
                                                        (v) => v.length > 0,
                                                    ),
                                            });
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    emailAddressTmp: '',
                                                },
                                            });
                                        },
                                        onChange: (e) => {
                                            dispatch({
                                                type: 'CHANGE_MODAL_STATE',
                                                payload: {
                                                    emailAddressTmp:
                                                        e.target.value,
                                                },
                                            });
                                        },
                                    }}
                                    data-walkthroughid={
                                        editMode.ufEmailAddressInput
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ minWidth: '80%' }}>
                                <TextEditor
                                    id="emailSubject"
                                    field="subject"
                                    setCursorPosition={setCursorPosition}
                                    textfield={{
                                        label: 'Subject',
                                        value: modalState?.action?.subject,
                                        multiline: false,
                                        onChange: (e) => {
                                            setActionState({
                                                subject: e.target.value,
                                            });
                                        },
                                    }}
                                    dataWalkthroughId={editMode.ufSubjectInput}
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={
                                                modalState?.action?.htmlBody
                                            }
                                            onChange={() => {
                                                setActionState({
                                                    htmlBody:
                                                        !modalState?.action
                                                            ?.htmlBody,
                                                });
                                            }}
                                            data-walkthroughid={
                                                editMode.ufHtmlBodyCheckbox
                                            }
                                            // inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="HTML Body"
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ minWidth: '80%' }}>
                                <TextEditor
                                    id="emailBody"
                                    field="body"
                                    setCursorPosition={setCursorPosition}
                                    textfield={{
                                        label: modalState?.action?.htmlBody
                                            ? 'HTML Body'
                                            : 'Body',
                                        value: modalState?.action?.body,
                                        onChange: (e) => {
                                            setActionState({
                                                body: e.target.value,
                                            });
                                        },
                                    }}
                                    multiselect={{
                                        label: 'Fields',
                                        placeholder: '+',
                                        staticEnum: fields,
                                    }}
                                    dataWalkthroughId={editMode.ufBodyTextarea}
                                />
                            </Col>
                            <Col>
                                <MultiSelect
                                    label="Fields"
                                    setCursorPosition={setCursorPosition}
                                    cursorPosition={cursorPosition}
                                    placeholder="+"
                                    staticEnum={fields}
                                    activeTextareaID={
                                        modalState?.activeTextareaID
                                    }
                                    activeTextareaField={
                                        modalState?.activeTextareaField
                                    }
                                    setState={setMultiSelectState}
                                    data-walkthroughid={editMode.ufFieldsTags}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            {modalState?.eventID &&
                modalState?.action?.actionType === 'WebHook' && (
                    <>
                        <Row style={{ minHeight: 80 }}>
                            <Col style={{ maxWidth: '15%' }}>
                                <LoadingFieldDropdown
                                    fieldName="Method"
                                    fieldValue={modalState?.action?.httpMethod}
                                    staticEnum={config.triggers.httpMethod}
                                    onChange={(v) => {
                                        setActionState({ httpMethod: v });
                                    }}
                                    noEmptyOption
                                    dataWalkthroughid={editMode.ufWebHookMethod}
                                />
                            </Col>
                            <Col>
                                <TextEditor
                                    id="webHookURI"
                                    setCursorPosition={setCursorPosition}
                                    field="uri"
                                    textfield={{
                                        label: 'URI',
                                        multiline: false,
                                        value: modalState?.action?.uri,
                                        onChange: (e) => {
                                            setActionState({
                                                uri: e.target.value,
                                            });
                                        },
                                    }}
                                    dataWalkthroughId={editMode.ufUriInput}
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 250 }}>
                            <Col>
                                <ActionHeaders
                                    setCursorPosition={setCursorPosition}
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ minWidth: '80%' }}>
                                <TextEditor
                                    id="webHookBody"
                                    setCursorPosition={setCursorPosition}
                                    field="body"
                                    textfield={{
                                        label: 'JSON Body',
                                        value: modalState?.action?.body,
                                        onChange: (e) => {
                                            setActionState({
                                                body: e.target.value,
                                            });
                                        },
                                    }}
                                    dataWalkthroughId={editMode.ufBodyTextarea}
                                />
                            </Col>
                            <Col>
                                <MultiSelect
                                    label="Fields"
                                    setCursorPosition={setCursorPosition}
                                    cursorPosition={cursorPosition}
                                    placeholder="+"
                                    staticEnum={fields}
                                    activeTextareaID={
                                        modalState?.activeTextareaID
                                    }
                                    activeTextareaField={
                                        modalState?.activeTextareaField
                                    }
                                    setState={setMultiSelectState}
                                    data-walkthroughid={editMode.ufFieldsTags}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            {modalMode !== 'Add' && (
                <DeleteBox
                    warningText={'Delete this trigger.'}
                    onClick={() => {
                        dispatch({
                            type: 'OPEN_MODAL',
                            payload: {
                                mode: 'Delete',
                                data: modalData,
                                state: modalState,
                                loading: false,
                                uploading: false,
                            },
                        });
                    }}
                    walkthroughId={editMode.ufDeleteBtn}
                />
            )}
        </>
    );
}

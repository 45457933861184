import { statusDisplayLevel } from './constant';

export const useStatus = (statusDisplay) => {
    const {
        statusDisplayLevel: displayLevel = -1,
        statusDisplayDetail: status,
    } = statusDisplay;

    const getStatus = (() => {
        switch (displayLevel) {
            case statusDisplayLevel.Ok:
                return {
                    color: 'green',
                    isAnimated: false,
                    hasStripes: false,
                    status,
                };

            case statusDisplayLevel.Warning:
                return {
                    color: 'yellow',
                    isAnimated: false,
                    hasStripes: false,
                    status,
                };

            case statusDisplayLevel.Alert:
                return {
                    color: 'red',
                    isAnimated: false,
                    hasStripes: false,
                    status,
                };

            case statusDisplayLevel.Ok_Animate:
                return {
                    color: 'green',
                    isAnimated: true,
                    hasStripes: true,
                    status,
                };

            case statusDisplayLevel.Warning_Animate:
                return {
                    color: 'yellow',
                    isAnimated: true,
                    hasStripes: true,
                    status,
                };

            case statusDisplayLevel.Alert_Animate:
                return {
                    color: 'red',
                    isAnimated: true,
                    hasStripes: true,
                    status,
                };

            default:
                return {
                    color: 'gray',
                    isAnimated: false,
                    hasStripes: false,
                    status,
                };
        }
    })();

    return getStatus;
};

export const isTruncated = (text) => {
    return text?.length > 20;
};

export const getTruncatedText = (text) => {
    if (isTruncated(text)) {
        return text;
    } else {
        return '';
    }
};

import { createContext, useReducer } from 'react';

import {
    APP_AUTH,
    DIRECT_ROUTING,
    OPERATOR_CONNECT,
} from 'pages/services/new/ServiceDashboard/Services/constants';
import { statusReducer } from 'pages/services/new/ServiceDashboard/Services/statusReducer';

const initialState = {
    [APP_AUTH]: {
        color: 'gray',
        animation: false,
    },
    [OPERATOR_CONNECT]: {
        color: 'gray',
        animation: false,
    },
    [DIRECT_ROUTING]: {
        color: 'gray',
        animation: false,
    },
    service: {
        [APP_AUTH]: {},
        [OPERATOR_CONNECT]: {},
        [DIRECT_ROUTING]: {},
    },
};
const ServicesDashboardContext = createContext(initialState);
const ServicesDashboardDispatchContext = createContext(() => ({}));
ServicesDashboardContext.displayName = 'ServicesDashboardContext';

export const ServicesDashboardProvider = (props) => {
    const [serviceStatus, dispatch] = useReducer(statusReducer, initialState);

    return (
        <ServicesDashboardContext.Provider value={serviceStatus}>
            <ServicesDashboardDispatchContext.Provider value={dispatch}>
                {props.children}
            </ServicesDashboardDispatchContext.Provider>
        </ServicesDashboardContext.Provider>
    );
};

export { ServicesDashboardContext, ServicesDashboardDispatchContext };

import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';

import { LoadingFieldDropdown } from '../../../../components';
import NumberFormatCustom, {
    FloatAllowNegative,
} from '../../../../components/maintenance/NumberFormatCustom';
import { onModalError } from '../../../../components/tables/TableAndModal';
import { axios } from '../../../../services/axios';
import walkthroughIds from '../walkthroughIds';

/*
 * Component that renders the form for the Plans Details tab when editing or adding a plan
 */
const ItemContent = () => {
    const dispatch = useDispatch();
    const [enumArray, setEnum] = useState([]);

    // modal Data from state
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return {
                ...state.modal,
            };
        },
    );

    // scope data from state
    const { requiredScope } = useSelector((state) => {
        return { ...state.login };
    });

    const { plans } = walkthroughIds;

    // function to fetch the fields for the form
    const fieldFetch = () => {
        //global level plan
        if (!modalState?.partnerID && !modalState?.wholesalerID) {
            return axios.get('/internationalratelists/', {
                params: { globalOnly: true },
            });
        }
        //wholesaler level plan
        if (!modalState?.partnerID && modalState?.wholesalerID) {
            return axios.get(
                `/internationalratelists/wholesaler/${modalState?.wholesalerID}`,
                {
                    params: { withPublic: true },
                },
            );
        }
        //partner level plan
        if (modalState?.partnerID && modalState?.wholesalerID) {
            return axios.get(
                `/internationalratelists/partner/${modalState?.partnerID}`,
                {
                    params: { withPublic: true },
                },
            );
        }
    };

    // calls fieldFetch and sets the Enum to the results
    useEffect(() => {
        fieldFetch().then((res) => {
            setEnum(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState?.partnerID, modalState?.wholesalerID]);

    useEffect(() => {
        if (
            !modalState.id &&
            (!modalState.internationalType ||
                !modalState.userType ||
                !modalState.trunkType ||
                !modalState.rgsType)
        )
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    internationalType: true,
                    userType: true,
                    trunkType: true,
                    rgsType: true,
                },
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Render the form for the 'Plans Details' Tab for edit and add plan
    return (
        <>
            <h3
                style={{
                    fontWeight: 500,
                    fontSize: '1.75rem',
                    marginBottom: '0.5rem',
                }}>
                Name &amp; Description
            </h3>
            <Row style={{ minHeight: 60 }}>
                <Col style={{ maxWidth: '80%' }}>
                    <TextField
                        placeholder="Plan's Name"
                        size="small"
                        label="Name"
                        fullWidth
                        error={Boolean(modalStateError?.name)}
                        helperText={modalStateError?.name}
                        value={modalState?.name}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: { name: e.target.value },
                            });
                        }}
                        data-walkthroughid={plans.ufNameInput}
                    />
                </Col>
                <Col style={{ maxWidth: '20%' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={modalState?.isActive}
                                defaultChecked
                                onChange={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            isActive: !modalState?.isActive,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufActiveCheckbox}
                            />
                        }
                        label="Active"
                    />
                </Col>
            </Row>

            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        fullWidth
                        label="Description"
                        placeholder="Description"
                        size="small"
                        value={modalState?.description}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    description: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufDescriptionInput}
                    />
                </Col>
            </Row>
            {requiredScope > 40 && (
                <>
                    <h3
                        style={{
                            fontWeight: 500,
                            fontSize: '1.75rem',
                            marginBottom: '0.5rem',
                        }}>
                        Belongs To
                    </h3>
                    {requiredScope === 80 && (
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <LoadingFieldDropdown
                                    searchable
                                    style={{
                                        shrink: true,
                                    }}
                                    fieldFetch={'/wholesalers'}
                                    fieldName="Wholesaler"
                                    dropDownValue="id"
                                    displayField="name"
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                wholesalerID: v,
                                                partnerID: null,
                                                internationalRateListID: null,
                                            },
                                        });
                                    }}
                                    fieldValue={modalState?.wholesalerID}
                                    onError={onModalError}
                                    dataWalkthroughid={plans.ufWholesalerSelect}
                                />
                            </Col>
                        </Row>
                    )}
                    {modalState?.wholesalerID && (
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <LoadingFieldDropdown
                                    searchable
                                    style={{
                                        shrink: true,
                                    }}
                                    fieldFetch={
                                        '/partners/' + modalState?.wholesalerID
                                    }
                                    fieldName="Partner"
                                    dropDownValue="id"
                                    displayField="name"
                                    onChange={(v) => {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                partnerID: v,
                                                internationalRateListID: null,
                                            },
                                        });
                                    }}
                                    hasDependency={true}
                                    dependency={modalState?.wholesalerID}
                                    fieldValue={modalState?.partnerID}
                                    onError={onModalError}
                                    dataWalkthroughid={plans.ufPartnerSelect}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <h3
                style={{
                    fontWeight: 500,
                    fontSize: '1.75rem',
                    marginBottom: '0.5rem',
                }}>
                International Rates
            </h3>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <LoadingFieldDropdown
                        searchable
                        staticEnum={enumArray}
                        fieldName="International Rate List"
                        dropDownValue="id"
                        displayField="name"
                        onChange={(v) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    internationalRateListID: v,
                                },
                            });
                        }}
                        fieldValue={modalState?.internationalRateListID}
                        onError={onModalError}
                        dataWalkthroughid={plans.ufInternationalRateSelect}
                    />
                </Col>
            </Row>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        label="International Discount"
                        name="numberformat"
                        fullWidth
                        size="small"
                        value={modalState?.internationalDiscount}
                        InputProps={{
                            inputComponent: FloatAllowNegative,
                        }}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    internationalDiscount: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufInternationalDiscountInput}
                    />
                </Col>
                <Col>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={modalState?.internationalType}
                                defaultChecked
                                onChange={() => {
                                    {
                                        dispatch({
                                            type: 'CHANGE_MODAL_STATE',
                                            payload: {
                                                internationalType:
                                                    !modalState?.internationalType,
                                            },
                                        });
                                    }
                                }}
                                data-walkthroughid={plans.ufBillCheckbox}
                            />
                        }
                        label="Bill"
                    />
                </Col>
            </Row>
            <h3
                style={{
                    fontWeight: 500,
                    fontSize: '1.75rem',
                    marginBottom: '0.5rem',
                }}>
                Configurations
            </h3>

            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        label="Country"
                        size="small"
                        error={Boolean(modalStateError?.country)}
                        helperText={modalStateError?.country}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={modalState?.country}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    country: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufCountryInput}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Prefix"
                        size="small"
                        error={Boolean(modalStateError?.countryPrefix)}
                        helperText={modalStateError?.countryPrefix}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={modalState?.countryPrefix}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    countryPrefix: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufPrefixInput}
                    />
                </Col>
            </Row>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        label="Company Setup Fee"
                        name="numberformat"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={modalState?.companySetupFee}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    companySetupFee: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufCompanySetupFeeInput}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Simple Porting Fee"
                        name="numberformat"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={modalState?.simplePortingFee}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    simplePortingFee: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufSimplePortingFeeInput}
                    />
                </Col>
            </Row>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <TextField
                        label="Complex Porting Fee"
                        name="numberformat"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={modalState?.complexPortingFee}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    complexPortingFee: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufComplexPortingFeeInput}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Other Porting Fee"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={modalState?.otherPortingFee}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    otherPortingFee: e.target.value,
                                },
                            });
                        }}
                        data-walkthroughid={plans.ufOtherPortingFeeInput}
                    />
                </Col>
            </Row>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th className="bootstrapTableRow heading">
                            Attributes
                        </th>
                        <th className="bootstrapTableRow heading">User</th>
                        <th className="bootstrapTableRow heading">Trunk</th>
                        <th className="bootstrapTableRow heading">Channels</th>
                        <th className="bootstrapTableRow heading">
                            Response Group
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="bootstrapTableRow">Setup Fee</td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.userSetupFee}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            userSetupFee: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufSetupFeeUserTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.trunkSetupFee}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trunkSetupFee: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufSetupFeeTrunkTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.sipChannelSetup}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            sipChannelSetup: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufSetupFeeChannelsTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.rgsSetupFee}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            rgsSetupFee: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    plans.ufSetupFeeResponseGroupTd
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="bootstrapTableRow">Rate</td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.userRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            userRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufRateUserTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.trunkRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trunkRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufRateTrunkTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.sipChannelRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            sipChannelRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufRateChannelsTd}
                            />
                        </td>
                        <td className="bootstrapTableRow">
                            <TextField
                                label=""
                                name="numberformat"
                                value={modalState?.rgsRate}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            rgsRate: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufRateResponseGroupTd}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="bootstrapTableRow">Bill</td>
                        <td
                            className="bootstrapTableRow"
                            style={{ textAlign: 'center' }}>
                            <Checkbox
                                checked={modalState?.userType}
                                defaultChecked
                                onChange={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            userType: !modalState?.userType,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufBillUserCheckbox}
                            />
                        </td>
                        <td
                            className="bootstrapTableRow"
                            style={{ textAlign: 'center' }}>
                            <Checkbox
                                checked={modalState?.trunkType}
                                defaultChecked
                                onChange={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            trunkType: !modalState?.trunkType,
                                        },
                                    });
                                }}
                                data-walkthroughid={plans.ufBillTrunkCheckbox}
                            />
                        </td>
                        <td
                            className="bootstrapTableRow"
                            style={{ textAlign: 'center' }}></td>
                        <td
                            className="bootstrapTableRow"
                            style={{ textAlign: 'center' }}>
                            <Checkbox
                                checked={modalState?.rgsType}
                                defaultChecked
                                onChange={() => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            rgsType: !modalState?.rgsType,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    plans.ufBillResponseGroupCheckbox
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

export default ItemContent;

import { useContext } from 'react';

import { TpmContext } from './TpmProvider';

export const useTpmContext = () => {
    const context = useContext(TpmContext);

    if (!context) {
        throw new Error('useTpmContext must be used within a TpmProvider');
    }

    return context;
};

import { AlertTitle } from '@chakra-ui/react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
} from 'components/v4';

function ErrorAssociatedAccCard({ state, navigate, darkMode }) {
    const isForbid = state?.error?.includes('Forbidden');
    const handleNavigate = () => {
        navigate('/dashboard-services');
    };
    return (
        <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
            <CardHeader>
                <Heading
                    fontSize="medium"
                    textColor={darkMode ? 'white' : null}>
                    Activation Error
                </Heading>
            </CardHeader>
            <CardBody textColor={darkMode ? 'white' : null}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Error!</AlertTitle>
                    <AlertDescription>
                        {state?.error ||
                            'Activation error. Please come back later.'}
                    </AlertDescription>
                </Alert>
            </CardBody>
            <CardFooter justifyContent="center">
                <Button variant="outline" onClick={handleNavigate}>
                    {isForbid ? 'Back to Dashboard' : 'Back'}
                </Button>
            </CardFooter>
        </Card>
    );
}

export default ErrorAssociatedAccCard;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
// import { Box } from '@chakra-ui/react';

import { useDashboardMenu } from 'context/DashboardMenuContext';

// Components
// import Text from 'components/v4/Text';
import Grid from 'components/v4/Grid';
import Alert from 'components/v4/Alert';
import AlertIcon from 'components/v4/AlertIcon';

import SkeletonPlaceholder from 'components/v4/Cards/DashboardServiceCardPlaceholder';
import DashboardServiceAddServiceCard from 'components/v4/Cards/DashboardServiceAddServiceCard/DashboardServiceAddServiceCard';
import DashboardServiceAddServiceCardButton from 'components/v4/Cards/DashboardServiceAddServiceCard/DashboardServiceAddServiceCardButton';
import DashboardServiceAddServiceCardTitle from 'components/v4/Cards/DashboardServiceAddServiceCard/DashboardServiceAddServiceCardTitle';
import DashboardServiceAddServiceCardContent from 'components/v4/Cards/DashboardServiceAddServiceCard/DashboardServiceAddServiceCardContent';

// Service Cards
import OperatorConnectCard from './Services/OperatorConnect';
import DirectRoutingCard from './Services/DirectRouting';
import SBCaaSCard from './Services/SBCaaS';
import OCCCard from './Services/OCC';
import TPMDashboard from './Services/TPM';
import ACSDashboard from './Services/ACS';
import AppAuthCard from './Services/AppAuth';
import VendorCard from './Services/Vendor/';

// API
import getAppAuthByCompanyId from 'API/AppAuth/getAppAuthByCompanyId';
import getAppAuthByID from 'API/AppAuth/getAppAuthById';

import { QUERY_CACHE_TIME, REFETCH_INTERVAL } from '.';
import { useServicesDashboardDispatch } from 'context/ServicesDashboardContext';
import { APP_AUTH } from './Services/constants';
import { trialEndInstructionBody } from 'pages/tpm/constants';

function CompanyApproveBanner({ companyData, partnerName }) {
    const isTrialExpired =
        companyData?.isTrial && Date.parse(companyData?.trialEnd) < Date.now();

    if (companyData === null) {
        return null;
    }

    if (!companyData?.approved) {
        return (
            <Alert
                status="info"
                sx={{ marginBottom: '25px', whiteSpace: 'pre-line' }}>
                <AlertIcon />
                Services will not provision until your company has been
                approved. Please contact {partnerName} for more information.
            </Alert>
        );
    }

    if (isTrialExpired) {
        return (
            <Alert
                status="info"
                sx={{ marginBottom: '25px', whiteSpace: 'pre-line' }}>
                <AlertIcon />
                {trialEndInstructionBody.map((p, index) => (
                    <span key={`${index + 1}`}>{p} &nbsp;</span>
                ))}
            </Alert>
        );
    }

    return null;
}

export default function DashboardServices({
    serviceWizard,
    services,
    companyData,
}) {
    const { isQueryDisabled } = useDashboardMenu();
    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );
    const partnerName = useSelector(
        (state) => state.navigationLists?.currentCompanyData?.partnerName,
    );
    const { state } = useLocation();
    const dispatch = useServicesDashboardDispatch();

    const {
        data: companyAppAuth,
        isLoading,
        refetch,
        isRefetching,
        isFetching,
    } = useQuery(
        ['companyAppAuth', serviceWizard.flow, companyID],
        async () => {
            try {
                const compAppAuth = await getAppAuthByCompanyId(companyID);
                const allCompanyAppAuthRes = await Promise.all(
                    compAppAuth.data.map((v) => getAppAuthByID(v.id)),
                );
                return allCompanyAppAuthRes;
            } catch (e) {
                console.error('error getting comp auth data', { e });
            }
        },
        {
            cacheTime: QUERY_CACHE_TIME,
            refetchInterval: REFETCH_INTERVAL,
            enabled: !isQueryDisabled,
            onSuccess: (data) => {
                dispatch({
                    type: `${APP_AUTH}/loaded_status`,
                    payload: {
                        serviceType: APP_AUTH,
                        service: {
                            ...data[0]?.data,
                            company: companyData,
                        },
                    },
                });
                serviceWizard.setMsServiceAuth(data[0]?.data);
                serviceWizard.setCompanyAppAuth(data?.map((v) => v.data));
            },
            // staleTime: 500,
        },
    );

    const AppAuthServiceType = companyAppAuth?.length > 0 ? 'AppAuth' : null;

    useEffect(() => {
        // Reset create service loading flag here
        // That way, when creating a service, and authing, the loading spinner
        // will stay true until they leave the page
        serviceWizard.setIsCreatingService(false);
        // getCompanyAppAuthData();

        // getAppAuthByCompanyId(companyID).then((res) => {
        //     serviceWizard.setCompanyAppAuth(res.data);
        //     console.log('run serv dbsetCompanyAppAuth', {res: res.data});
        //     const newData = res.data.map((companyAuth) => {
        //         if (companyAuth.appAuthTypeID === 'Microsoft') {
        //             getAppAuthByID(companyAuth.id).then((res) => {
        //                 serviceWizard.setMsServiceAuth(res.data);
        //                 console.log('run serv setMsServiceAuth', {
        //                     res: res.data,
        //                 });
        //             });
        //         }
        //     });
        // });
    }, [companyID]);

    useEffect(() => {
        if (!state?.message) return;

        let isMounted = true;
        if (state?.message) {
            toast.error(state?.message, { autoClose: 3000 });
        }

        if (isMounted) {
            window.history.replaceState({}, ''); // HACK: reset history state when refresh
        }
        return () => {
            isMounted = false;
            toast.dismiss();
        };
    }, []);

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Loading.
     */
    if (
        services === null ||
        serviceWizard.companyAppAuth === null ||
        isLoading
    ) {
        return (
            <SkeletonPlaceholder
                skeletons={13}
                rows={1}
                columns={3}
                gap={4}
                items={3}
            />
        );
    }

    /**
     * No available services.
     */
    if (!services?.length) {
        return (
            <>
                <Alert
                    status="info"
                    variant="inline-accent"
                    sx={{ marginBottom: '25px', whiteSpace: 'pre-line' }}>
                    <AlertIcon />
                    You haven&apos;t created any services. Click the Add Service
                    button below to create your first service.
                </Alert>
                <DashboardServiceAddServiceCard
                    onClick={() => serviceWizard.setFlow('CreateService')}
                    dataWalkthroughId={'/dashboard-services/card/add-service'}>
                    <DashboardServiceAddServiceCardContent>
                        <DashboardServiceAddServiceCardTitle>
                            Add Service
                        </DashboardServiceAddServiceCardTitle>
                        <DashboardServiceAddServiceCardButton />
                    </DashboardServiceAddServiceCardContent>
                </DashboardServiceAddServiceCard>
            </>
        );
    }
    /**
     * Services.
     */
    return (
        <>
            <CompanyApproveBanner
                companyData={companyData}
                partnerName={partnerName}
            />

            <Grid columns={3} gapX={8} gapY={10}>
                {[
                    {
                        serviceType: AppAuthServiceType,
                        ...companyAppAuth[0]?.data,
                    },
                    ...services.sort((a, b) => {
                        const priorityServices = [
                            'TeamsPhoneMobile',
                            'DirectRouting',
                            'OperatorConnect',
                            'AppAuth',
                        ];
                        return (
                            priorityServices.indexOf(b?.serviceType) -
                            priorityServices.indexOf(a?.serviceType)
                        );
                    }),
                ].map((service) => {
                    switch (service.serviceType) {
                        case 'AppAuth':
                            return (
                                <AppAuthCard
                                    key="AppAuth"
                                    service={{
                                        ...companyAppAuth[0]?.data,
                                        serviceType: 'AppAuth',
                                    }}
                                    serviceWizard={serviceWizard}
                                    companyData={companyData}
                                    companyAppAuth={companyAppAuth[0]?.data}
                                />
                            );
                        case 'TeamsPhoneMobile':
                            return (
                                <TPMDashboard
                                    key="TPM"
                                    service={service}
                                    ocService={services?.find(
                                        (s) =>
                                            s.serviceType === 'OperatorConnect',
                                    )}
                                    serviceWizard={serviceWizard}
                                    companyData={companyData}
                                />
                            );
                        case 'ACSDirectRouting':
                            return (
                                <ACSDashboard
                                    key={service.id}
                                    service={service}
                                />
                            );
                        case 'OperatorConnect':
                            return (
                                <OperatorConnectCard
                                    key={service.id}
                                    service={service}
                                    serviceWizard={serviceWizard}
                                    companyAppAuth={serviceWizard.companyAppAuth.find(
                                        (appAuth) =>
                                            service.appAuthType?.includes(
                                                appAuth.appAuthTypeID,
                                            ),
                                    )}></OperatorConnectCard>
                            );
                        case 'DirectRouting':
                            return (
                                <DirectRoutingCard
                                    companyData={companyData}
                                    key={service.id}
                                    service={service}
                                    serviceWizard={serviceWizard}
                                    companyAppAuth={serviceWizard.companyAppAuth.find(
                                        (appAuth) =>
                                            service.appAuthType?.includes(
                                                appAuth.appAuthTypeID,
                                            ),
                                    )}></DirectRoutingCard>
                            );
                        case 'SBCaaS':
                            return (
                                <SBCaaSCard
                                    companyData={companyData}
                                    key={service.id}
                                    service={service}
                                    serviceWizard={serviceWizard}
                                    companyAppAuth={serviceWizard.companyAppAuth.find(
                                        (appAuth) =>
                                            service.appAuthType?.includes(
                                                appAuth.appAuthTypeID,
                                            ),
                                    )}></SBCaaSCard>
                            );
                        case 'OperatorConnectConferencing':
                            return (
                                <OCCCard
                                    key={service.id}
                                    service={service}
                                    serviceWizard={serviceWizard}
                                />
                            );

                        case 'VendorDefined':
                            return (
                                <VendorCard
                                    service={service}
                                    serviceWizard={serviceWizard}
                                />
                            );
                    }
                })}
                {/* {vendorList?.length && <VendorList list={vendorList} />} */}
                <DashboardServiceAddServiceCard
                    onClick={() => serviceWizard.setFlow('CreateService')}
                    dataWalkthroughId={'/dashboard-services/card/add-service'}>
                    <DashboardServiceAddServiceCardContent>
                        <DashboardServiceAddServiceCardTitle>
                            Add Service
                        </DashboardServiceAddServiceCardTitle>
                        <DashboardServiceAddServiceCardButton />
                    </DashboardServiceAddServiceCardContent>
                </DashboardServiceAddServiceCard>
            </Grid>
        </>
    );
}

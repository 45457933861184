import {
    CONTINUE,
    ERROR,
    FOUND,
    initialState,
    NOT_FOUND,
    REQUEST_NUMBER,
    RESEND,
    RESET,
    SUBMIT_NUMBER,
} from '@/store/constants/service';

const tpm = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_NUMBER: {
            return {
                ...state,
                number: payload.number,
                error: '',
                lastUpdate: Date.now(),
                operatorStatus: 'queued',
                currentStep: 1,
            };
        }

        case CONTINUE: {
            return {
                ...state,
                error: '',
                operatorStatus: state.currentStep === 1 ? 'pending' : 'found',
                formState: 'idle',
            };
        }

        // resend code
        case RESEND: {
            return {
                ...state,
                error: '',
                currentStep: 2,
                lastUpdate: Date.now(),
            };
        }

        case FOUND: {
            return {
                ...state,
                error: '',
                operatorStatus: 'found',
                formState: 'idle',
                currentStep: 2,
            };
        }

        case NOT_FOUND: {
            return {
                ...state,
                operatorStatus: 'notfound',
            };
        }

        // TODO: change this later
        case SUBMIT_NUMBER: {
            return {
                ...state,
                code: payload.code,
                error: '',
                operatorStatus: 'found',
                status: 'PASS',
                currentStep: 3,
            };
        }

        case ERROR: {
            return {
                ...state,
                formState: 'error',
                error: payload,
            };
        }

        case RESET:
        case 'LOGOUT':
        case 'CLEAR_TOKEN':
        case 'CHANGE_CURRENT_COMPANY':
        case 'CHANGE_CURRENT_PARTNER': {
            return initialState;
        }

        default:
            return state;
    }
};

export { tpm };

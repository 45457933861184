import {
    Box,
    MenuItem,
    Checkbox as MuiCheckbox,
    TextField as MuiTextField,
    Tooltip,
} from '@mui/material';

import MaterialTable from 'material-table';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '@mui/icons-material/Edit';
import { useDisclosure } from '@chakra-ui/react';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from '../../../utils/MaterialTableIcons';
import BillingNotesModal from './BillingNotesModal';

import store from '@/store/store';
import walkthroughIds from '../walkthroughIds';
import { customTablePaperStyle } from './constants';

const Checkbox = forwardRef((props, ref) => {
    return (
        <MuiCheckbox
            sx={{
                '&.Mui-checked': {
                    color: '#8cc34e',
                },
            }}
            ref={ref}
            {...props}
        />
    );
});

Checkbox.displayName = 'Checkbox';

const TextField = forwardRef((props, ref) => {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <MuiTextField
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        sx: customTablePaperStyle(darkMode),
                    },
                },
            }}
            ref={ref}
            {...props}
        />
    );
});

TextField.displayName = 'TextField';

// default columns for the table
const columns = [
    {
        field: 'description',
        title: ' Billing Note',
        width: 570,
        editComponent: (props) => {
            return (
                <TextField
                    variant="standard"
                    size="small"
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            );
        },
    },
    {
        field: 'requiredScope',
        title: 'Scope',
        width: 125,
        editComponent: (props) => {
            return (
                <TextField
                    select
                    variant="standard"
                    size="small"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}>
                    {scopes.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            );
        },
    },
    {
        field: 'isActive',
        title: 'Active',
        width: 120,
        type: 'boolean',
        render: (rowData) => {
            return (
                <Tooltip title="Enable edit mode to change">
                    <Checkbox
                        onMouseDown={() => {
                            store.dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    rowClicked: rowData?.tableData?.id,
                                },
                            });
                        }}
                        onMouseLeave={() => {
                            store.dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload: {
                                    rowClicked: -1,
                                },
                            });
                        }}
                        checked={rowData.isActive}
                        className="hover:cursor-not-allowed"
                        readOnly
                    />
                </Tooltip>
            );
        },
        editComponent: (props) => {
            return (
                <Checkbox
                    checked={props?.rowData.isActive}
                    onChange={(e) => props.onChange(e.target.checked)}
                />
            );
        },
    },
    {
        field: 'billingNoteLocation',
        title: 'Display Location',
        width: 220,
        render: (rowData) => {
            if (rowData.billingNoteLocation === 0) {
                return 'Top';
            }
            if (rowData.billingNoteLocation === 1) {
                return 'Bottom';
            } else {
                return 'Top';
            }
        },
        editComponent: (props) => {
            return (
                <TextField
                    select
                    variant="standard"
                    size="small"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}>
                    {locations.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            );
        },
    },
];
// enums for inputs
const scopes = [
    { value: 20, label: 20 },
    { value: 40, label: 40 },
    { value: 60, label: 60 },
    { value: 80, label: 80 },
];
const locations = [
    { value: 0, label: 'Top' },
    { value: 1, label: 'Bottom' },
];

/**
 * Table that is displayed in the billing note section of the company billing form
 */
const BillingNoteTable = (props) => {
    const muiTheme = useMemo(
        () =>
            createTheme({
                typography: {
                    color: '#000000',
                },
                head: {
                    backgroundColor: null,
                },
                overrides: {
                    MuiToolbar: {
                        root: {
                            color: 'rgba(255,255,255, 0.7)',
                            backgroundColor: '#424242',
                        },
                    },
                    MuiTable: {
                        root: {
                            WebkitTextFillColor:
                                'rgba(255, 255, 255, 0.7) !important',
                            backgroundColor: '#424242 !important',
                        },
                    },
                    MuiTableHead: {
                        root: {
                            backgroundColor: '#424242 !important',
                        },
                    },
                    MuiTableCell: {
                        root: {
                            borderBottom: null,
                            borderTop: '1px solid rgba(224, 224, 224, 0.1)',
                        },
                    },
                    MuiTablePagination: {
                        root: {
                            backgroundColor: 'white',
                        },
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: null,
                        },
                    },
                    MuiIconButton: {
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                    },
                },
            }),
        [],
    );

    const dispatch = useDispatch();
    const { setBillingState } = props;
    const [isActive, setIsActive] = useState(true);
    const [desc, setDesc] = useState();
    const [location, setLocation] = useState(0);
    const [scope, setScope] = useState(20);
    const [rows, setRows] = useState([]);
    const [loading] = useState(false);

    // state access
    const { state } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });
    const { rowClicked } = state || -1;

    const { darkMode } = useSelector((state) => state.settings);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { modal } = walkthroughIds.companies.billing;

    // function for when the modal is submitted, ie a note is added
    const onSubmitHandler = () => {
        let tempNotes;
        if (state.companyBillingSettings) {
            tempNotes = [
                ...state.companyBillingSettings.billingNotes,
                {
                    requiredScope: scope,
                    billingNoteLocation: location,
                    description: desc,
                    isActive: isActive,
                    billingSettingsID: state.companyBillingSettings.id,
                },
            ];
        } else {
            tempNotes = [
                {
                    requiredScope: scope,
                    billingNoteLocation: location,
                    description: desc,
                    isActive: isActive,
                    billingSettingsID: state.companyBillingSettings.id,
                },
            ];
        }
        setBillingState({ billingNotes: tempNotes });
        onClose();
    };

    // append rows based on the state
    useEffect(() => {
        if (state?.companyBillingSettings?.billingNotes && loading === false) {
            let result = [];
            state.companyBillingSettings.billingNotes.forEach((v) => {
                const temp = {
                    ...v,
                };
                result.push(temp);
            });
            setRows([...result]);
        }
    }, [state?.companyBillingSettings?.billingNotes, loading]);

    // sets array so dispatch can spread empty array for delete handling
    useEffect(() => {
        if (state.deletedNoteIDs === undefined) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    deletedNoteIDs: [],
                },
            });
        }
    }, []);

    const billingNotesModalProps = {
        isOpen,
        onClose,
        modal,
        desc,
        setDesc,
        scopes,
        setScope,
        locations,
        location,
        setLocation,
        isActive,
        setIsActive,
        onSubmitHandler,
    };

    const foundRowClicked = rows.find(
        (row) => row?.tableData?.id === rowClicked,
    );

    // table that billing notes renders
    return (
        <ThemeProvider theme={darkMode ? muiTheme : null}>
            <Box
                className="billing-note-table"
                sx={{
                    th: {
                        backgroundColor: darkMode
                            ? '#6a696a !important'
                            : '#FFFFFF !important',
                    },
                    thead: {
                        th: {
                            backgroundColor: darkMode
                                ? 'var(--chakra-colors-gray-600)!important'
                                : 'var(--chakra-colors-gray-200)!important',
                        },
                        span: {
                            fontSize: '16px',
                            fontWeight: '600',
                        },
                    },
                    td: {
                        color: darkMode ? 'black !important' : null,
                    },
                    boxShadow: darkMode ? 1 : 0,
                }}>
                <MaterialTable
                    title="Billing Notes"
                    icons={{
                        ...tableIcons,
                        Edit: () => (
                            <Edit
                                sx={{
                                    animation:
                                        Boolean(foundRowClicked) &&
                                        'shake 2s cubic-bezier(.36,.07,.19,.97) infinite',
                                    color: foundRowClicked ? 'red' : null,
                                }}
                            />
                        ),
                    }}
                    columns={columns}
                    data={rows}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        sorting: true,
                        pageSize: 3,
                        pageSizeOptions: [],
                        draggable: false,
                    }}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: onOpen,
                        },
                    ]}
                    editable={{
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    const dataDelete = [...rows];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setRows([...dataDelete]);
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            deletedNoteIDs: [
                                                {
                                                    id: oldData.id,
                                                },
                                                ...state.deletedNoteIDs,
                                            ],
                                        },
                                    });
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            companyBillingSettings: {
                                                ...state.companyBillingSettings,
                                                billingNotes: dataDelete,
                                            },
                                        },
                                    });
                                    resolve();
                                }, 1000);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    const dataUpdate = [...rows];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setRows([...dataUpdate]);
                                    let result = [];
                                    state.companyBillingSettings.billingNotes.forEach(
                                        (v) => {
                                            if (
                                                v.description ===
                                                oldData.description
                                            ) {
                                                // if one state value matches the old data - update this value
                                                const temp = {
                                                    ...newData,
                                                    id: v.id,
                                                };
                                                result.push(temp);
                                            } else {
                                                // make sure to grab that host and keep it in state
                                                const temp = { ...v };
                                                result.push(temp);
                                            }
                                        },
                                    );
                                    setBillingState({
                                        billingNotes: result,
                                    });
                                    resolve();
                                }, 1000);
                            }),
                    }}
                    localization={{
                        header: {
                            actions: '',
                        },
                        body: {
                            editRow: {
                                deleteText:
                                    'Are you sure you want to delete this note?',
                            },
                        },
                    }}
                />
            </Box>

            {/* Diaglogue that opens when you add a billing note */}
            <BillingNotesModal {...billingNotesModalProps} />
        </ThemeProvider>
    );
};

export default BillingNoteTable;

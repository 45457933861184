import { useState, useEffect, useRef } from 'react';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

import {
    MultiChild,
    LoadingFieldDropdown,
    DeleteBox,
    ModalCopy,
} from '../../../components';
import Item from './Item';
import config from '../../../config.json';
import { addSpacing } from '../../../utils/utils';
import { axios } from '../../../services/axios';

import walkthroughIds from './walkthroughIds';

/*
 * Component for rendering the base table for the 'Permissions' Tab from the sidebar
 */
const Permissions = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [disabled, setDisabled] = useState(false);
    const [companies, setCompanies] = useState([]);

    // modal state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
        stateError: modalStateError,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // table state access
    const { parent } = useSelector((state) => {
        return {
            ...state.table,
        };
    });

    // nav list access from state
    const { currentCompany, currentPartner, currentWholesaler } = useSelector(
        (state) => {
            return { ...state.navigationLists };
        },
    );

    // scope access from state
    const { requiredScope, currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // default columns for the table
    const defaultColumns = [
        {
            title: 'Company',
            field: 'companyName',
        },
        {
            title: 'Permission Set',
            field: 'name',
        },
        {
            title: 'Required Scope',
            field: 'requiredScopeName',
        },
        {
            title: 'Read Only Permissions',
            field: 'readOnly',
            hiddenByColumnsButton: true,
            hidden: true,
            type: 'boolean',
        },
        {
            title: 'Opt Out of New Permissions',
            field: 'optOutOfNew',
            hiddenByColumnsButton: true,
            hidden: true,
            type: 'boolean',
        },
    ];

    const { ...ufPermissionPage } = walkthroughIds.permissions;

    // fetch endpoint function
    const tableFetch = () => {
        switch (currentScope) {
            case 80:
                return '/permissionsets/80';
            case 60:
                return '/permissionsets/60/' + currentCompany;
            case 40:
                return '/permissionsets/40/' + currentCompany;
            default:
                return '/permissionsets/20/' + currentCompany;
        }
    };

    const companyChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                companyID: v,
            },
        });
    };

    const permissionChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                name: e.target.value,
            },
        });
    };

    const optOutOfNewChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                optOutOfNew: e.target.checked,
            },
        });
    };
    const readOnlyChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                readOnly: e.target.checked,
            },
        });
    };

    // opens a delete modal and resets the multichild
    const deleteOnClick = () => {
        dispatch({
            type: 'RESET_TABLE_MULTICHILD',
            payload: 'all',
        });
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                mode: 'Delete',
                data: modalData,
                state: modalState,
                loading: false,
                uploading: false,
            },
        });
    };

    // function to render the modal content based on what mode is selected
    const modalContent = () => {
        switch (modalMode) {
            case 'Copy':
                return (
                    <ModalCopy
                        modalData={modalData}
                        modalState={modalState}
                        modalStateError={modalStateError}
                        setModalState={(payload) => {
                            dispatch({
                                type: 'CHANGE_MODAL_STATE',
                                payload,
                            });
                        }}
                    />
                );
            case 'Delete':
                return (
                    <>
                        Are you sure you would like to delete this Permission
                        Set? <br />
                        Before deleting, please ensure that this Permission Set
                        is not in use.
                        <Alert
                            severity="warning"
                            style={{ marginBottom: 10, marginTop: 10 }}>
                            This action cannot be undone.
                        </Alert>
                    </>
                );
            default:
                return (
                    <>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                {requiredScope === 20 ? (
                                    <TextField
                                        fullWidth
                                        label={'Company'}
                                        disabled
                                        type="text"
                                        value={modalState?.companyName}
                                        data-walkthroughid={
                                            ufPermissionPage.ufCompany
                                        }
                                    />
                                ) : (
                                    <LoadingFieldDropdown
                                        searchable
                                        fieldName="Company"
                                        dropDownValue="id"
                                        displayField="name"
                                        onChange={companyChangeHandler}
                                        staticEnum={companies}
                                        fieldValue={modalState?.companyID}
                                        dataWalkthroughid={
                                            ufPermissionPage.ufCompany
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col className="ml-2">
                                <FormControlLabel
                                    label="Read Only Permissions"
                                    control={
                                        <Checkbox
                                            sx={{ display: 'none' }}
                                            size="small"
                                            checked={modalState?.readOnly}
                                            onChange={readOnlyChangeHandler}
                                            data-walkthroughid={
                                                ufPermissionPage.ufReadOnly
                                            }
                                        />
                                    }
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    label="Opt Out of New Permissions"
                                    control={
                                        <Checkbox
                                            sx={{ display: 'none' }}
                                            size="small"
                                            checked={modalState?.optOutOfNew}
                                            onChange={optOutOfNewChangeHandler}
                                            data-walkthroughid={
                                                ufPermissionPage.ufOptOut
                                            }
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ minHeight: 60 }}>
                            <Col>
                                <TextField
                                    fullWidth
                                    label="Permission Set"
                                    type="text"
                                    value={modalState?.name}
                                    error={Boolean(modalStateError?.name)}
                                    helperText={modalStateError?.name}
                                    onChange={permissionChangeHandler}
                                    data-walkthroughid={
                                        ufPermissionPage.ufPermissionSet
                                    }
                                />
                            </Col>
                        </Row>
                        <DeleteBox
                            warningText={
                                'Please ensure that this Permission Set is not in use.'
                            }
                            onClick={deleteOnClick}
                            walkthroughId={ufPermissionPage.ufDeleteBtn}
                        />
                    </>
                );
        }
    };

    // function to determine if the rowData can be edited
    const canEdit = (rowData) => {
        switch (requiredScope) {
            case 20:
                return !(
                    !rowData?.companyID || //is global
                    rowData?.requiredScope > 20 ||
                    rowData?.companyID !== currentCompany
                );
            case 40:
                return !(
                    !rowData?.companyID || //is global
                    rowData?.requiredScope > 40 ||
                    rowData?.companyID !== currentCompany
                );
            case 60:
                return !(
                    !rowData?.companyID || //is global
                    rowData?.requiredScope > 60 ||
                    rowData?.companyID !== currentCompany
                );
            default:
                return true;
        }
    };

    // Sets the the current companies drop down based on partner and wholesaler
    useEffect(() => {
        axios
            .get('/companies/list', {
                params: {
                    wholesalerID: currentWholesaler,
                    partnerID: currentPartner,
                },
                headers: {
                    'X-RequestScope': currentScope,
                },
            })
            .then((res) => {
                setCompanies(res.data);
            })
            .catch((err) => {
                throw new Error(err);
            });
    }, [currentPartner, currentWholesaler, currentScope]);

    //  returns a multichild with modal content from the above function
    return (
        <MultiChild
            tableRef={tableRef}
            tableFetch={tableFetch()}
            title="Permission Sets"
            defaultColumns={defaultColumns}
            modalFetch={`/permissionset/${modalData?.id}`}
            modalAdd="/permissionset/"
            onTableSucceed={(data) => {
                return data.map(({ requiredScopeName, ...rest }) => ({
                    requiredScopeName: addSpacing(requiredScopeName),
                    ...rest,
                }));
            }}
            modalUpdate="/permissionset/"
            modalDelete={`/permissionset/${modalData?.id}`}
            modalTitle={`${modalMode} Permission Sets:  ${modalData?.companyName} - ${modalData?.name}`}
            tabs={
                !disabled
                    ? [
                          { details: true },
                          {
                              component: <Item disabled={disabled} />,
                              label: 'Permissions',
                          },
                      ]
                    : [
                          {
                              component: <Item disabled={disabled} />,
                              label: 'Permissions',
                          },
                      ]
            }
            modalUpload={(freshData, state) => {
                return {
                    ...freshData,
                    ...state,
                };
            }}
            additionalActions={[
                (rowData) => ({
                    icon: () => (
                        <EditRoundedIcon
                            fontSize="small"
                            data-walkthroughid={
                                rowData.tableData.id === 0
                                    ? 'table/button/editBtn'
                                    : null
                            }
                        />
                    ),
                    tooltip: 'Edit Permission Set',
                    position: 'row',
                    hidden: !canEdit(rowData),
                    onClick: (event, rowData) => {
                        const currentlyDisabled = !canEdit(rowData);
                        setDisabled(currentlyDisabled);
                        dispatch({
                            type: 'OPEN_CHILD_TABLE',
                            payload: rowData,
                        });
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    },
                }),
                (rowData) => ({
                    icon: () => (
                        <VisibilityRoundedIcon
                            fontSize="small"
                            data-walkthroughid={
                                rowData.tableData.id === 0
                                    ? 'table/button/viewBtn'
                                    : null
                            }
                        />
                    ),
                    tooltip: 'View Permission Set',
                    position: 'row',
                    hidden: canEdit(rowData),
                    onClick: (event, rowData) => {
                        const currentlyDisabled = !canEdit(rowData);
                        setDisabled(currentlyDisabled);
                        dispatch({
                            type: 'OPEN_CHILD_TABLE',
                            payload: rowData,
                        });
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    },
                }),
            ]}
            modalCopy={`/permissionset/${modalData?.id}/copy/${currentCompany}`}
            groupable
            filterable
            loadingFieldDropdown={{
                onChange: (v) => {
                    const childData = parent.find(
                        (parentValue) => parentValue.id === v,
                    );
                    dispatch({
                        type: 'CHANGE_CHILD_TABLE',
                        payload: childData,
                    });
                    const currentlyDisabled = !canEdit(childData);
                    if (currentlyDisabled) {
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 0,
                        });
                    } else if (!currentlyDisabled && disabled) {
                        dispatch({
                            type: 'CHANGE_BREADCRUMB_VIEW',
                            payload: 1,
                        });
                    }
                    setDisabled(currentlyDisabled);
                },
            }}
            maxBodyHeight={window.innerHeight * config.plainView.tableHeight}
            rowActions={{
                copiable: true,
            }}
            modalValidate={() => {
                var newErrorState;
                if (modalMode === 'Copy') {
                    newErrorState = {
                        copyName:
                            (!modalState?.copyName ||
                                modalState?.copyName?.length < 1 ||
                                modalState?.copyName?.length > 50) &&
                            'Field is required and maximum field length is 50 characters.',
                    };
                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });
                    return !newErrorState.copyName;
                }
                newErrorState = {
                    name:
                        (!modalState?.name || modalState?.name?.length < 1) &&
                        'Field is required.',
                };
                dispatch({
                    type: 'CHANGE_MODAL_STATE_ERROR',
                    payload: newErrorState,
                });
                return !newErrorState.name;
            }}
            dynamicScope
            modalContent={modalContent()}
        />
    );
};

export default Permissions;

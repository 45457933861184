import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';

import { TabView } from '../../../../components';
import AvailableBlock from './AvailableBlock';
import Form from './RequestBlockForm';

/*
 * Request New Block compoennt tab in the Numbers option in the Sidebar
 */
const RequestBlockIndex = () => {
    const dispatch = useDispatch();

    //state access
    const { tab } = useSelector((state) => {
        return { ...state.view };
    });

    const { currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    // Dispatch to change the tab view
    useEffect(() => {
        if (Number.isInteger(tab)) {
            dispatch({
                type: 'CHANGE_MODAL_TAB_VIEW',
                payload: {
                    level1: 1,
                    level2: 0,
                },
            });
        }
    }, [dispatch, tab]);

    // Alert if your company is not approved
    if (currentCompanyData?.status === 3) {
        return (
            <Alert severity="info">
                You cannot request public numbers until your company is
                approved.
            </Alert>
        );
    }

    // return tab view with availiible number blocks and the regular form
    return (
        <TabView
            onChange={(e, v) => {
                dispatch({
                    type: 'CHANGE_TAB_VIEW',
                    payload: {
                        level1: 1,
                        level2: v,
                    },
                });
            }}
            value={tab['level2']}
            tabLabels={['Available Number Blocks', 'Request New Block']}
            isChild
            parentLabel={'Request New Block'}
            contents={[
                <AvailableBlock key={'availableBlock'} />,
                <Form key={'requestBlockForm'} />,
            ]}
        />
    );
};

export default RequestBlockIndex;

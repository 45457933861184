export const initValidation = { number: '', message: '' };
export const NOT_VALID = 'Could not confirm numbers\' validity';
export const NOT_AVAILABLE = 'Could not confirm numbers\' availability';
export const NUMBER_EXCEED_LIMIT = 'Numbers exceed the limit of 1000 lines';
export const INVALID_CHAR = 'Number contains invalid characters';
export const NOT_ALLOCATE = 'Number still assigned to user';
const NOT_FOUND = 'Number not found';
const NOT_TPM_NUM = 'Number not on TPM Service';

const ERR_NOT_FOUND = 'Numbers not found';
const ERR_ALLOCATED = 'Numbers still assigned to users';
const ERR_TPM_NUM = 'Numbers not on TPM Service';
const ERR_INVALID_CHAR = 'Numbers contain invalid characters';

export const ERROR_MESSAGES = {
    [ERR_NOT_FOUND]: NOT_FOUND,
    [ERR_ALLOCATED]: NOT_ALLOCATE,
    [ERR_TPM_NUM]: NOT_TPM_NUM,
    [ERR_INVALID_CHAR]: INVALID_CHAR,
};

export const actionButtons = [
    {
        label: 'Add row',
        'aria-label': 'Add',
    },
    {
        label: 'Remove row',
        'aria-label': 'Clear',
    },
];

export const groupMessages = (messages) => {
    return messages.reduce(
        (acc, { number, message }) => {
            const [type] = message.split(':');
            if (type === NOT_VALID) {
                acc.validity.push(number);
            } else if (type === INVALID_CHAR) {
                acc.invalidChars.push(number);
            } else {
                acc.availability.push(number);
            }
            return acc;
        },
        { validity: [], availability: [], invalidChars: [] },
    );
};

export const clearErrors = (setTelephoneNumbers, setValidateNumbers) => {
    setTelephoneNumbers((prev) =>
        prev.filter((item) => item.status === 'PASS'),
    );
    setValidateNumbers([initValidation]);
};

export const clearErrorsDn = (setUpdate) => {
    setUpdate((prev) => prev.filter((item) => item.status !== 'FAIL'));
};

export const refreshTable = (setUpdate) => {
    setUpdate([{ number: '', status: '' }]);
};

export const search = (term, telephoneNumbers, setFilteredData) => {
    return telephoneNumbers?.reduce((acc, telephoneNumber) => {
        const matchedSearch =
            telephoneNumber?.number?.includes(term) ||
            telephoneNumber.status.toLowerCase().includes(term.toLowerCase());
        if (matchedSearch) {
            acc.push(telephoneNumber);
        }
        setFilteredData(acc);
        return acc;
    }, []);
};

export const exceedsLimits = (
    isTrial,
    telephoneNumbers,
    numbers,
    trialNumberLimit,
) => {
    const filteredTable = telephoneNumbers.filter((row) => row.number !== '');
    if (isTrial && filteredTable.length + numbers.length > trialNumberLimit) {
        alert(
            `Cannot exceed a total of ${trialNumberLimit} numbers while Company is on Trial. Please copy less numbers.`,
        );
        return true;
    }
    if (filteredTable.length + numbers.length > 1000) {
        alert('You have exceeded the maximum number of lines allowed (1000).');
        return true;
    }
    return false;
};

import {
    EditIcon,
    ExternalLinkIcon,
    InfoIcon,
    RepeatIcon,
    DeleteIcon,
} from '@chakra-ui/icons';
import {
    Box,
    IconButton,
    SkeletonCircle,
    SkeletonText,
    Tooltip,
    forwardRef,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MenuItem as ChakraMenuItem, Image, Text } from '@/components/v4';
import { Card, CardBody, CardHeader } from 'components/v4/Card';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DashboardServiceCardStatus from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardOcStatusbar';

import getService from 'API/Service/getService';
import updateVendorDefinedStatus from 'API/Service/updateVendorDefinedStatus';
import { useDashboardMenu } from 'context/DashboardMenuContext';
import { classNames } from 'utils/classNames';
import { getTruncatedText, isTruncated, useStatus } from './helper';
import { serviceReducer } from './serviceReducer';

import {
    QUERY_CACHE_TIME,
    REFETCH_INTERVAL,
} from '@/pages/services/new/ServiceDashboard';
import vendor from 'assets/images/vendor.png';
import { ACTIONS } from './constant';
import { axios } from '@/services/axios';
import { toast } from 'react-toastify';
const MenuItem = forwardRef((props, ref) => {
    const { darkMode } = useSelector((state) => state.settings);

    return (
        <ChakraMenuItem
            sx={{
                '& svg': {
                    color: darkMode ? 'white' : 'black',
                },
            }}
            ref={ref}
            {...props}
        />
    );
});
const VendorCard = ({ service }) => {
    const { darkMode } = useSelector((state) => state.settings);
    const { isQueryDisabled, setIsQueryDisabled } = useDashboardMenu();
    const [state, dispatch] = useReducer(serviceReducer, service);
    const status = useStatus(state.statusDisplay);

    const { status: serviceStatus } = useQuery({
        queryKey: ['Service', state.id],
        queryFn: async () => {
            try {
                const res = await getService(state.id);
                if (!res.data) throw Error('No service found');
                return res.data;
            } catch (error) {
                console.log('Error at getting service: ', error);
            }
        },
        onSuccess: (service) => {
            dispatch({
                type: ACTIONS.UPDATE_SERVICE,
                payload: service,
            });
            dispatch({
                type: ACTIONS.GET_SERVICE_ACTIONS,
                payload: service.actions,
            });
        },
        cacheTime: QUERY_CACHE_TIME,
        refetchInterval: REFETCH_INTERVAL,
        enabled: !isQueryDisabled,
    });

    const updateStatus = useMutation({
        mutationFn: (serviceStatusID) =>
            updateVendorDefinedStatus(state.id, serviceStatusID),
        onSuccess: (newService) => {
            dispatch({
                type: ACTIONS.UPDATE_SERVICE,
                payload: newService,
            });
            if (newService?.actions?.length > 0)
                dispatch({
                    type: ACTIONS.UPDATE_SERVICE_ACTIONS,
                    payload: newService.actions,
                });
        },
    });

    const handleClick = (action) => {
        if (action.serviceStatusID) {
            return updateStatus.mutate(action.serviceStatusID);
        } else if (action.externalURL) {
            window.open(action.externalURL, '_blank');
        }
    };

    let loadingContent = (
        <MenuItem icon={<SkeletonCircle size="3" />}>
            <SkeletonText noOfLines={1} />
        </MenuItem>
    );

    const deleteService = async () => {
        try {
            await axios.delete(`/service/vendordefined/${service?.id}`);
            console.log('this is query disabled', isQueryDisabled);
            // previously stopped refreshing, will re-trigger refetch
            if (isQueryDisabled) {
                setIsQueryDisabled(false);
            }
            toast.success('Vendor defined service removed successfully!');
        } catch (e) {
            toast.error(e.response.data.message);
        }
    };

    return (
        <Card
            id={getTruncatedText(service.serviceType)}
            bg={darkMode ? 'dark.tcap' : null}
            maxW="400px"
            minW="200px"
            paddingLeft="10px"
            borderRadius="0.25rem"
            className="!shadow-lg">
            <DashboardServiceCardStatus size="lg" status={status} />

            <CardHeader
                alignItems="center"
                justifyContent="left"
                display="flex"
                p={4}
                h="40px">
                <Tooltip
                    hasArrow
                    label={getTruncatedText(service.name)}
                    placement="bottom-end">
                    <span className="truncate">
                        <Text
                            className="truncate pr-3"
                            fontSize="lg"
                            fontWeight="bold">
                            {service.name}
                        </Text>
                    </span>
                </Tooltip>
                <DashboardServiceCardHeaderActionButton
                    dataWalkthroughId={getTruncatedText(service.name)}>
                    {state?.allowEdit && (
                        <Link
                            id="edit-service"
                            className="bg-transparent !no-underline hover:text-inherit"
                            to={`/services/vendordefined/${service.id}/edit`}
                            state={service}>
                            <MenuItem icon={<EditIcon />}>
                                <Text marginBottom={'0'}>Edit Service</Text>
                            </MenuItem>
                        </Link>
                    )}
                    {state?.allowUserEdit && (
                        <Link
                            id="activate-users"
                            className="bg-transparent !no-underline hover:text-inherit"
                            to={`/services/vendordefined/${service.id}/users`}
                            state={service}>
                            <MenuItem icon={<EditIcon />}>
                                <Text marginBottom={'0'}>Activate Users</Text>
                            </MenuItem>
                        </Link>
                    )}
                    {state?.allowNumberEdit && (
                        <Link
                            id="activate-numbers"
                            className="bg-transparent !no-underline hover:text-inherit"
                            to={`/services/vendordefined/${service.id}/numbers`}
                            state={service}>
                            <MenuItem icon={<EditIcon />}>
                                <Text marginBottom={'0'}>Activate Numbers</Text>
                            </MenuItem>
                        </Link>
                    )}
                    {serviceStatus === 'loading'
                        ? loadingContent
                        : state?.actions?.length > 0 &&
                          state?.actions?.map((action, index) => {
                              // TODO: need switch case to dynamically render the actions
                              const onClick = () => {
                                  return handleClick(action);
                              };

                              if (action?.externalURL) {
                                  return (
                                      <Link
                                          id={index}
                                          key={index}
                                          className="bg-transparent !no-underline hover:text-inherit"
                                          to={`${action?.externalURL}`}
                                          target="_blank"
                                          onClick={onClick}>
                                          <MenuItem icon={<ExternalLinkIcon />}>
                                              <Text>{action.name}</Text>
                                          </MenuItem>
                                      </Link>
                                  );
                              }
                              return (
                                  <MenuItem
                                      key={index}
                                      icon={<RepeatIcon />}
                                      onClick={onClick}>
                                      <Text>{action.name}</Text>
                                  </MenuItem>
                              );
                          })}
                    {/* Delete a service  */}
                    {state?.allowDelete && (
                        <MenuItem
                            onClick={() => deleteService()}
                            icon={<DeleteIcon />}>
                            <Text marginBottom={'0'}>Delete Service</Text>
                        </MenuItem>
                    )}
                </DashboardServiceCardHeaderActionButton>
            </CardHeader>
            <CardBody>
                <Box>
                    <Image
                        src={
                            isEmpty(service.displayImage)
                                ? vendor
                                : // eslint-disable-next-line no-undef
                                  process.env.NODE_ENV === 'development'
                                  ? import.meta.env.VITE_APP_IMAGE_BASE +
                                    '/images/' +
                                    service.displayImage
                                  : window.location.origin +
                                    '/images/' +
                                    service.displayImage
                        }
                        sx={{
                            maxWidth: '50px',
                            margin: '0 auto 15px auto',
                        }}
                    />
                </Box>
                {/* Status */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={4}>
                    <Text fontWeight="500">Status</Text>
                    <Box
                        display="flex"
                        flexDir="row"
                        justifyContent="flex-end"
                        className="truncate">
                        <Tooltip
                            hasArrow
                            label={getTruncatedText(status.status)}
                            placement="bottom-end">
                            <span
                                className={classNames(
                                    isTruncated(status.status)
                                        ? 'truncate'
                                        : '',
                                    darkMode ? 'text-white' : 'text-black',
                                    'ml-20',
                                )}>
                                {status.status}
                            </span>
                        </Tooltip>
                        {state.statusDisplay?.statusDisplayExtended && (
                            <span className="mx-1 inline-flex flex-shrink-0 self-center">
                                <Tooltip
                                    label={
                                        state.statusDisplay
                                            ?.statusDisplayExtended
                                    }
                                    hasArrow
                                    placement="bottom">
                                    <IconButton
                                        icon={
                                            <InfoIcon
                                                color={
                                                    darkMode ? 'white' : 'black'
                                                }
                                            />
                                        }
                                        size="xs"
                                        bg="transparent"
                                        _hover={{
                                            bg: 'transparent',
                                            boxShadow: 'none',
                                        }}
                                        _active={{
                                            bg: 'transparent',
                                            boxShadow: 'none',
                                        }}
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </Box>
                </Box>
            </CardBody>
        </Card>
    );
};

export default VendorCard;

import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
    InputGroup,
    InputLeftElement,
    InputRightElement,
    List,
    ListIcon,
    ListItem,
    Tooltip,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
// import { BsDownload, BsFilter } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';

import { Input } from 'components/v4';

function TableToolbar({ onSearch, type, darkMode, spin, onSpin }) {
    const [searchTerm, setSearchTerm] = useState('');

    const searchRef = useRef(null);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        onSearch(e.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        onSearch('');
        searchRef.current.focus();
    };

    return (
        <List
            display="flex"
            flexDir="col"
            // width="full"
            justifyContent="flex-end"
            sx={{
                '& > li': {
                    height: '40px',
                },
            }}>
            <ListItem mr={4}>
                <InputGroup minW={['200px', '220px']}>
                    <InputLeftElement justifyContent="flex-start">
                        <SearchIcon
                            boxSize={4}
                            color={darkMode ? 'white' : 'black'}
                        />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder={`Search ${type} numbers`}
                        _placeholder={{ fontSize: 'sm' }}
                        // px={10}
                        ps={6}
                        pe={8}
                        variant="flushed"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="!bg-transparent"
                        customRef={searchRef}
                    />
                    {searchTerm && (
                        <InputRightElement>
                            <CloseIcon
                                className="cursor-pointer"
                                onClick={handleClearSearch}
                                boxSize={4}
                                color={darkMode ? 'white' : 'black'}
                            />
                        </InputRightElement>
                    )}
                </InputGroup>
            </ListItem>
            <ListItem display="flex" alignItems="center">
                <Tooltip
                    label={spin ? 'Refreshing' : `Refresh ${type} numbers`}>
                    <span>
                        <ListIcon
                            cursor="pointer"
                            as={MdRefresh}
                            boxSize="18px"
                            mr={0}
                            onClick={onSpin}
                            color={darkMode ? 'white' : 'black'}
                            className={
                                spin
                                    ? 'animate-[spin_0.5s_linear_infinite]'
                                    : ''
                            }
                        />
                    </span>
                </Tooltip>
            </ListItem>
        </List>
    );
}

export default TableToolbar;

import { Alert, AlertIcon, Text } from 'components/v4';
import InstructionMessages from './InstructionMessages';

import { operatorInstruction } from './constants';

function QueueStatusMessage({
    operatorStatus,
    state,
    darkMode,
    ...pollingProps
}) {
    const { tpmError } = pollingProps;

    if (operatorStatus === 'error' || state?.error || tpmError) {
        return (
            <Alert status="error" className="max-w-sm">
                <AlertIcon />
                <Text>{state.error || tpmError}</Text>
            </Alert>
        );
    }
    return (
        <>
            <InstructionMessages
                instructionBody={operatorInstruction}
                darkMode={darkMode}
                keywords={['15 minutes', 'auto refresh']}
            />
        </>
    );
}

export default QueueStatusMessage;

import { useState, useMemo, useRef } from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import { FaLayerGroup } from 'react-icons/fa';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import SortByAlphaRoundedIcon from '@material-ui/icons/SortByAlphaRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Visibility from '@material-ui/icons/VisibilityRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { Button } from 'react-bootstrap';

import { LoadingFieldDropdown, DataTable } from '..';
import { axios } from '../../services/axios';
import {
    dateStringToDate,
    stringCompareIgnoreCase,
    byString,
    extractFilename,
} from '../../utils/utils';
import walkthroughIds from './walkthroughIds';
import walkthroughIdsApprove from '../../pages/organisations/walkthroughIds';

// float search function
export const floatSearch = (v, rowData, field) => {
    return Math.abs(parseFloat(v) - rowData[field]) < Number.EPSILON;
};

// a function to build the default structure of the table
export const loadingColumnsConstructor = (defaultColumns) => {
    return defaultColumns.map(
        // eslint-disable-next-line no-unused-vars
        ({ defaultGroupOrder, defaultGroupSort, ...rest }, idx) => {
            return {
                ...rest,
                type: 'loading',
                // eslint-disable-next-line no-unused-vars
                render: (rowData) => {
                    return (
                        <span>
                            <Skeleton />
                        </span>
                    );
                },
            };
        },
    );
};

// empty data to show loading state
// export const loadingData = [{}, {}, {}];

export default function ReduxTable(props) {
    const {
        defaultColumns,
        alphabetFilterField,
        title,
        onSync,
        groupable,
        noColumnsButton,
        prependAdditionalActions,
        noExport,
        maxBodyHeight,
        editable,
        selection,
        selectionProps,
        toolbarButtonAlignment = 'right',
        showSelectAllCheckbox,
        loadingColumns,
        filterable,
        add,
        noTitle,
        modalInitState,
        remoteData,
        tableRef,
        rowActions,
        progressBar,
        additionalActions,
        titleProps,
        data,
        columns,
        detailPanel,
        noRefresh,
        parentChildData,
        overrideOnRowSelected,
        noActionsColumn,
    } = props;
    const dispatch = useDispatch();
    //force state hack
    const [, force] = useState();
    const [alphabetOpen, setAlphabetOpen] = useState(false);
    const addActionRef = useRef();

    // current scope from state
    const { currentScope } = useSelector((state) => {
        return { ...state.login };
    });

    // current table state
    const {
        state,
        filtering,
        originalData,
        index: alphabetIndex,
        grouping,
        loading,
        alphabet,
        invoiceType,
    } = useSelector((state) => {
        return { ...state.table };
    });

    const { ufAddBtn, ufCopyBtn, ufEditBtn, ufViewBtn, ...ufTableBtns } =
        walkthroughIds.table;

    const { ufApproveButton } = walkthroughIdsApprove.companies.general;

    // Transforms the columns to columns usable by material
    const transformColumns = useMemo(() => {
        return state?.columns?.map((v) => {
            const { type, field, customSort, doNotOverrideSort } = v;

            switch (type) {
                case 'datetime':
                    return {
                        ...v,
                        customFilterAndSearch: (v, rowData) =>
                            new RegExp(v, 'i').test(
                                dateStringToDate(rowData[field], 'datetime'),
                            ),
                    };
                case 'date':
                    return {
                        ...v,
                        customFilterAndSearch: (v, rowData) =>
                            new RegExp(v, 'i').test(
                                dateStringToDate(rowData[field], 'date'),
                            ),
                    };
                case 'time':
                    return {
                        ...v,
                        customFilterAndSearch: (v, rowData) =>
                            new RegExp(v, 'i').test(
                                dateStringToDate(rowData[field], 'time'),
                            ),
                    };

                default:
                    return {
                        ...v,
                        customSort: customSort
                            ? customSort
                            : doNotOverrideSort
                              ? null
                              : function (l, r) {
                                    let leftValue;
                                    let rightValue;

                                    if (l === null) {
                                        leftValue = '';
                                    } else if (typeof l !== 'object') {
                                        leftValue = l;
                                    } else if (
                                        typeof l[field] !== 'undefined'
                                    ) {
                                        leftValue = l[field];
                                    } else {
                                        leftValue = byString(l, field);
                                    }

                                    if (r === null) {
                                        rightValue = '';
                                    } else if (typeof r !== 'object') {
                                        rightValue = r;
                                    } else if (
                                        typeof r[field] !== 'undefined'
                                    ) {
                                        rightValue = r[field];
                                    } else {
                                        rightValue = byString(r, field);
                                    }

                                    if (
                                        typeof leftValue === 'number' &&
                                        typeof rightValue === 'number'
                                    ) {
                                        return leftValue - rightValue;
                                    }
                                    return stringCompareIgnoreCase(
                                        leftValue,
                                        rightValue,
                                    );
                                },
                    };
            }
        });
    }, [state?.columns]);

    //action to open a modal
    const defaultEditActions = (event, rowData) => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                mode: 'Edit',
                data: rowData,
                state: rowActions?.editable?.init,
                hash: '',
            },
        });
    };

    var toolbarActions = [
        {
            icon: ({ disabled }) => (
                <LoadingFieldDropdown
                    fieldName="Invoice Type"
                    disabled={loading ?? disabled}
                    onChange={(v) => {
                        dispatch({
                            type: 'RESET_TABLE',
                        });
                        dispatch({
                            type: 'CHANGE_TABLE_INVOICE_TYPE',
                            payload: v,
                        });
                    }}
                    noEmptyOption
                    fieldValue={invoiceType}
                    displayField="name"
                    dropDownValue="value"
                    staticEnum={
                        currentScope === 20
                            ? [{ value: 0, name: 'Company' }]
                            : currentScope === 40
                              ? [
                                    { value: 0, name: 'Company' },
                                    { value: 1, name: 'Partner' },
                                ]
                              : [
                                    { value: 0, name: 'Company' },
                                    { value: 1, name: 'Partner' },
                                    { value: 2, name: 'Wholesaler' },
                                ]
                    }
                    dataWalkthroughid={ufTableBtns.ufInvoiceBtn}
                />
            ),
            tooltip: 'Invoice Type',
            position: 'toolbar',
            hidden: !rowActions?.invoiceType,
            disabled: Boolean(rowActions?.invoiceType?.disabled),
            onClick: () => {},
        },
        {
            icon: () => (
                <FormControl size="small">
                    <Select
                        data-walkthroughid={ufTableBtns.ufSortBtn}
                        startAdornment={
                            <SortByAlphaRoundedIcon
                                style={{
                                    marginBottom: '5%',
                                    paddingRight: '5%',
                                }}
                            />
                        }
                        value={alphabet}
                        open={alphabetOpen}
                        onChange={(event) => {
                            dispatch({
                                type: 'CHANGE_TABLE_ALPHABET_FILTER',
                                payload: event.target.value,
                            });
                            alphabetFilter(
                                event.target.value,
                                alphabetFilterField,
                            );
                        }}>
                        <MenuItem value="All">All</MenuItem>

                        {alphabetIndex?.map((v, idx) => (
                            <MenuItem
                                key={idx}
                                value={v}
                                data-walkthroughid={`/table/toolbar/viewColumnBtn/${v}/${idx}`}>
                                {v}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ),
            tooltip: 'Filter ' + title + ' Alphabetically',
            position: 'toolbar',
            hidden: !alphabetFilterField,
            onClick: () => {
                setAlphabetOpen(!alphabetOpen);
            },
        },

        {
            icon: () => (
                <FilterListRoundedIcon
                    data-walkthroughid={ufTableBtns.ufFilterBtn}
                />
            ),
            tooltip: 'Filter ' + title,
            position: 'toolbar',

            onClick: () => {
                dispatch({ type: 'CHANGE_TABLE_FILTERING' });
            },
            hidden: !filterable,
        },
        {
            icon: () => (
                <FaLayerGroup data-walkthroughid={ufTableBtns.ufGroupBtn} />
            ),
            tooltip: 'Group ' + title,
            position: 'toolbar',

            onClick: () => {
                dispatch({ type: 'CHANGE_TABLE_GROUPING' });
            },
            hidden: !groupable,
        },
        {
            icon: () => <SyncRoundedIcon />,
            tooltip: 'Sync ' + title,
            position: 'toolbar',

            onClick: () => {
                onSync();
            },
            hidden: !onSync,
        },
        {
            icon: () => (
                <RefreshIcon data-walkthroughid={ufTableBtns.ufRefreshBtn} />
            ),
            tooltip: 'Refresh ' + title,
            position: 'toolbar',
            onClick: () => {
                if (remoteData) {
                    return tableRef.current && tableRef.current.onQueryChange();
                }
                dispatch({
                    type: 'CHANGE_TABLE_LOADING',
                });
                dispatch({
                    type: 'CHANGE_TABLE_STATE',
                    payload: {
                        columns: loadingColumns,
                        data: data || state?.data,
                    },
                });
            },
            hidden: noRefresh,
        },
    ];

    // icons and actions for the table
    var actions = [
        ...(prependAdditionalActions || []),
        // toolbar actions
        ...(toolbarActions || []),
        //array of actions to present the icons
        {
            icon: () => <ImageRoundedIcon fontSize="small" />,
            tooltip: 'Change Logo',
            position: 'row',
            hidden: !rowActions?.changeLogo,
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: 'Change Logo',
                        data: rowData,
                        state: modalInitState,
                    },
                });
            },
        },

        (rowData) => ({
            icon: () => (
                <CheckCircleRoundedIcon
                    data-walkthroughid={`${ufApproveButton}/${rowData?.tableData.id}`}
                    fontSize="small"
                />
            ),
            tooltip: 'Approve ' + title,
            position: 'row',
            hidden: !(rowActions?.approvable && !rowData?.approved),
            onClick: () =>
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: 'Approval',
                        data: rowData,
                        state: rowActions?.approvable?.init,
                    },
                }),
        }),

        (rowData) => ({
            icon: () => {
                return (
                    <FileCopyRoundedIcon
                        fontSize="small"
                        data-walkthroughid={
                            rowData.tableData.id === 0 ? ufCopyBtn : null
                        }
                    />
                );
            },
            tooltip: 'Copy ' + title,
            hidden: !rowActions?.copiable,
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        mode: 'Copy',
                        data: rowData,
                        state: {
                            ...(rowActions?.copiable?.init ?? {}),
                            copyName: `Copy of ${rowData?.name}`,
                        },
                    },
                });
            },
        }),
        (rowData) => ({
            icon: () => (
                <ListRoundedIcon
                    fontSize="small"
                    data-walkthroughid={
                        rowData.tableData.id === 0 ? ufViewBtn : null
                    }
                />
            ), //for viewing child tables only
            tooltip: 'View ' + rowActions?.childTable?.name,
            hidden: !rowActions?.childTable?.hidden(rowData),
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: rowData,
                });
            },
        }),

        (rowData) => ({
            icon: () => (
                <EditRoundedIcon
                    fontSize="small"
                    data-walkthroughid={
                        `${ufEditBtn}/${rowData?.tableData.id}`
                        // rowData.tableData.id === 0 ? ufEditBtn : null
                    }
                />
            ),
            tooltip: rowActions?.multiChildTable?.name,
            hidden: !rowActions?.multiChildTable,
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: rowData,
                });
                dispatch({
                    type: 'CHANGE_BREADCRUMB_VIEW',
                    payload: rowActions?.multiChildTable?.to ?? 0,
                });
            },
        }),
        {
            icon: () => <Visibility fontSize="small" />, //for viewing child tables only
            tooltip: 'View ' + rowActions?.childComponent,
            hidden: !rowActions?.childComponent,
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_CHILD_COMPONENTS',
                    payload: rowData,
                });
            },
        },
        (rowData) => ({
            icon: ({ disabled }) => {
                if (disabled) {
                    return;
                }
                return (
                    <EditRoundedIcon
                        fontSize="small"
                        data-walkthroughid={
                            `${ufEditBtn}/${rowData?.tableData.id}`
                            // rowData.tableData.id === 0 ? ufEditBtn : null
                        }
                    />
                );
            },
            tooltip: 'Edit ' + title,
            hidden: !rowActions?.editable,
            position: 'row',
            onClick: rowActions?.editable?.render || defaultEditActions,
        }),
        (rowData) => ({
            icon: ({ disabled }) => {
                if (disabled) {
                    return;
                }
                return <DeleteForeverRoundedIcon fontSize="small" />;
            },
            tooltip: 'Delete ' + title,
            hidden: !rowActions?.delete,
            position: 'row',
            onClick:
                rowActions?.delete?.render ??
                function (event, rowData) {
                    dispatch({
                        type: 'OPEN_MODAL',
                        payload: {
                            mod: 'Delete',
                            data: rowData,
                            state: rowActions?.delete?.init,
                        },
                    });
                },
            disabled: rowActions?.delete?.disabled
                ? rowActions?.delete?.disabled(rowData)
                : false,
        }),
        (rowData) => ({
            icon: ({ disabled }) => {
                if (disabled) {
                    return;
                }
                return (
                    <EditRoundedIcon
                        fontSize="small"
                        data-walkthroughid={
                            `${ufEditBtn}/id-${rowData?.tableData.id}`
                            // rowData.tableData.id === 0 ? ufEditBtn : null
                        }
                    />
                );
            },
            tooltip: 'Edit ' + title,
            hidden:
                !rowActions?.conditionalEditable ||
                rowActions?.conditionalEditable?.disabled(rowData),
            position: 'row',
            onClick:
                rowActions?.conditionalEditable?.render || defaultEditActions,
        }),
        /*(rowData) => ({
icon: ({disabled}) => {
if (disabled) {
return;
}
return (
<FontAwesomeIcon
icon={faMicrosoft}
style={{width: 20, height: 20}}
/>
);
},
position: 'row',
tooltip: 'Provide Access',
hidden:
!rowActions?.viewableScript ||
rowActions?.viewableScript?.disabled(rowData),
onClick:
rowActions?.viewableScript?.onClick ??
function (event, rowData) {
dispatch({
type: 'OPEN_SCRIPT',
payload: rowData,
});
},
}),*/
        (rowData) => ({
            icon: () => {
                return (
                    <Button
                        size="sm"
                        style={{
                            fontSize: 10,
                            backgroundColor: '#fafafa',
                            color: 'black',
                            borderColor: 'black',
                        }}
                        data-walkthroughid={
                            rowData.tableData.id === 0
                                ? ufTableBtns.ufCdrBtn
                                : null
                        }>
                        CDR
                    </Button>
                );
            },
            position: rowActions?.cdr?.position,
            tooltip: 'Export Invoice Line To CDR',
            onClick:
                rowActions?.cdr?.onClick ??
                function (e, rowData) {
                    axios
                        .get('/invoice/' + rowData?.id + '/export/cdr', {
                            responseType: 'blob',
                        })
                        .then((res) => {
                            const filename = extractFilename(
                                res.headers['content-disposition'] ||
                                    'attachment; filename="Sample_File.csv"; filename*=UTF-8\'\'CDR_Wounds%20Australia_.csv',
                            );
                            const blob = new Blob([res.data]);
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.download = filename;
                            link.click();
                        })
                        .catch(() => {
                            window.alert(
                                'Failed exporting invoice, please try again later.',
                            );
                        });
                },
            hidden: !rowActions?.cdr || invoiceType !== 0,
        }),
        (rowData) => ({
            icon: () => {
                return (
                    <Button
                        size="sm"
                        style={{
                            fontSize: 10,
                            backgroundColor: '#fafafa',
                            color: 'black',
                            borderColor: 'black',
                        }}
                        data-walkthroughid={
                            rowData.tableData.id === 0
                                ? ufTableBtns.ufExportBtn
                                : null
                        }>
                        {' '}
                        EXPORT
                    </Button>
                );
            },
            hidden: !rowActions?.xero,
            position: rowActions?.xero?.position,
            tooltip: 'Export Invoice Line',
            onClick:
                rowActions?.xero?.onClick ??
                function (e, rowData) {
                    axios
                        .get('/invoice/' + rowData?.id + '/export/xero', {
                            responseType: 'blob',
                        })
                        .then((res) => {
                            const filename = extractFilename(
                                res.headers['content-disposition'] ||
                                    'attachment; filename="Sample_File.csv"; filename*=UTF-8\'\'CDR_TrialCompany_.csv',
                            );
                            const blob = new Blob([res.data]);
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.download = filename;
                            link.click();
                        })
                        .catch(() => {
                            window.alert(
                                'Failed exporting invoice, please try again later.',
                            );
                        });
                },
        }),
        ...(additionalActions || []),
    ];

    var customActionsWithoutColumn = [
        ...(prependAdditionalActions || []),
        ...(toolbarActions || []),
        ...(additionalActions || []),
    ];

    //filter by alphabet letter
    const alphabetFilter = async (alpha, field) => {
        if (alpha === 'All') {
            dispatch({
                type: 'CHANGE_TABLE_STATE',
                payload: {
                    columns: defaultColumns,
                    data: originalData,
                },
            });
            return;
        }
        var filtered = await originalData.filter((rowData) => {
            return (
                (alpha === '#' &&
                    (rowData[field][0].toUpperCase() < 'A' ||
                        rowData[field][0].toUpperCase() > 'Z')) ||
                rowData[field][0].toUpperCase() === alpha
            );
        });

        dispatch({
            type: 'CHANGE_TABLE_STATE',
            payload: {
                columns: defaultColumns,
                data: filtered,
            },
        });

        force();
    };

    return (
        <>
            {!noTitle && add && (
                <h1
                    className="mb-2 text-[2.5rem] font-medium"
                    aria-label="outer toolbar title">
                    {title}{' '}
                </h1>
            )}
            <DataTable
                title={
                    <>
                        {!noTitle && !add && (
                            <h1
                                className="mb-2 text-[2.5rem] font-medium"
                                aria-label="inner toolbar title">
                                {title}{' '}
                            </h1>
                        )}
                        {progressBar ? (
                            <div style={{ width: 300, marginTop: '1%' }}>
                                {' '}
                                {progressBar}{' '}
                            </div>
                        ) : (
                            <> </>
                        )}
                        {titleProps && (
                            <div style={{ marginBottom: '2%' }}>
                                {titleProps}{' '}
                            </div>
                        )}

                        {add && (
                            <Button
                                data-walkthroughid={ufAddBtn}
                                className="btn btn-primary btn-sm"
                                style={{ marginBottom: '5%' }}
                                onClick={() => {
                                    dispatch({
                                        type: 'OPEN_MODAL',
                                        payload: {
                                            state: modalInitState || {},
                                            loading: false,
                                            mode: 'Add',
                                            hash: '',
                                        },
                                    });
                                    dispatch({
                                        type: 'CHANGE_MODAL_HASH',
                                    });
                                }}>
                                {' '}
                                <AddRoundedIcon
                                    fontSize="small"
                                    style={{ marginBottom: '2%' }}
                                />{' '}
                                {add?.buttonText ?? title}
                            </Button>
                        )}
                    </>
                }
                actions={noActionsColumn ? customActionsWithoutColumn : actions}
                titleName={title}
                options={{
                    filtering,
                    selection,
                    selectionProps,
                    showSelectAllCheckbox,
                    exportAllData: true,
                    search: filterable,
                    exportButton: !noExport && { csv: true, pdf: true },
                    headerStyle: {
                        position: 'sticky',
                        top: 0,
                    },
                    cellStyle: { textAlign: 'left', wordWrap: 'break-word' },
                    columnsButton: !noColumnsButton,
                    maxBodyHeight,
                    exportFileName: `${title}_Table_Export`,
                    paging: true,
                    addRowPosition: 'first',
                    grouping,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [50, 100, 250, 500],
                    toolbarButtonAlignment,
                    pageSize: 50,
                    searchAutoFocus: false,
                    showTextRowsSelected: false,
                    emptyRowsWhenPaging: false,
                }}
                addActionRef={addActionRef}
                state={{
                    columns: columns || transformColumns || loadingColumns,
                    data: data || state?.data,
                }}
                editable={editable}
                parentChildData={parentChildData}
                remoteData={remoteData}
                tableRef={tableRef}
                detailPanel={detailPanel}
                overrideOnRowSelected={overrideOnRowSelected}
            />
        </>
    );
}

import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextField, InputLabel } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { Form, LoadingFieldDropdown } from '../../../../components';
import walkthroughIds from 'pages/telephony/numbers/walkthroughIds';

/*
 * Form to request a new number block inside the 'Request new block' Tab of the Numbers Tab of TCAP
 */
const RequestBlockForm = () => {
    const dispatch = useDispatch();

    // State access
    const { loading, state, stateError } = useSelector((state) => {
        return { ...state.modal };
    });

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { requestNewBlock } = walkthroughIds;

    // validation function for submission of the form
    const validateOnSubmit = () => {
        const newErrorState = {
            contactName:
                (!state?.contactName || state?.contactName?.length < 1) &&
                'Field is required.',
            contactPhone:
                (!state?.contactPhone || state?.contactPhone?.length < 1) &&
                'Field is required.',
            contactEmail:
                (!state?.contactEmail || state?.contactEmail?.length < 1) &&
                'Field is required.',
            comments:
                state?.comments?.length > 255 &&
                'Maximum field length is 255 characters.',
            country:
                (!state?.country || state?.country?.length < 1) &&
                'Field is required.',
            region:
                (!state?.region || state?.region?.length < 1) &&
                'Field is required.',
            state:
                (!state?.state || state?.state?.length < 1) &&
                'Field is required.',
            suburb:
                (!state?.suburb || state?.suburb?.length < 1) &&
                'Field is required.',
            street:
                (!state?.street || state?.street?.length < 1) &&
                'Field is required.',
            postcode: !state?.postcode && 'Postcode is required.',
            blockSize: !state?.blockSize && 'Field is required.',
            quantity: !state?.quantity && 'Field is required.',
        };
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: newErrorState,
        });

        return !(
            newErrorState.region ||
            newErrorState.state ||
            newErrorState.suburb ||
            newErrorState.street ||
            newErrorState.postcode ||
            newErrorState.blockSize ||
            newErrorState.quantity ||
            newErrorState.contactPhone ||
            newErrorState.contactEmail ||
            newErrorState.contactName ||
            newErrorState.quantity
        );
    };

    // open a new model and set the hash when the page loads
    useEffect(() => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                mode: 'Edit',
                hash: '',
                loading: false,
                show: true,
            },
        });
        dispatch({
            type: 'CHANGE_MODAL_HASH',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return a form with the required fields for a new number block request
    return (
        <Form
            reduxState={'modal'}
            loading={loading}
            validateOnSubmit={validateOnSubmit}
            mode={'Add'}
            reduxPrefix={''}
            formAdd={'/numberblock/requestnew/' + currentCompany}
            formContent={
                <>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                label="Contact Name"
                                value={state?.contactName}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.contactName)}
                                helperText={stateError?.contactName}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            contactName: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.contactName}
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Contact Phone"
                                value={state?.contactPhone}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.contactPhone)}
                                helperText={stateError?.contactPhone}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            contactPhone: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    requestNewBlock.contactPhone
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Contact Email"
                                value={state?.contactEmail}
                                type="email"
                                fullWidth
                                error={Boolean(stateError?.contactEmail)}
                                helperText={stateError?.contactEmail}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            contactEmail: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={
                                    requestNewBlock.contactEmail
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <LoadingFieldDropdown
                                id={requestNewBlock.blockSize}
                                staticEnum={[1, 10, 100]}
                                noEmptyOption
                                fieldName="Block Size"
                                errorText={
                                    !state?.blockSize && 'Field is required.'
                                }
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            blockSize: v,
                                        },
                                    });
                                }}
                                fieldValue={state?.blockSize}
                                toggleLimit={6}
                                onError={(payload) =>
                                    dispatch({
                                        type: 'CHANGE_MODAL_BACKEND_ERROR',
                                        payload,
                                    })
                                }
                                dataWalkthroughid={requestNewBlock.blockSize}
                            />
                        </Col>
                        <Col>
                            <LoadingFieldDropdown
                                staticEnum={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                noEmptyOption
                                fieldName="Quantity"
                                errorText={
                                    !state?.quantity && 'Field is required.'
                                }
                                onChange={(v) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            quantity: v,
                                        },
                                    });
                                }}
                                fieldValue={state?.quantity}
                                dataWalkthroughid={requestNewBlock.quantity}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                fullWidth
                                id="Country"
                                label="Country"
                                type="text"
                                value={state?.country}
                                error={Boolean(stateError?.country)}
                                helperText={stateError?.country}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            country: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.country}
                            />
                        </Col>
                        <Col>
                            <TextField
                                fullWidth
                                id="Region"
                                label="Region"
                                type="text"
                                value={state?.region}
                                error={Boolean(stateError?.region)}
                                helperText={stateError?.region}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            region: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.region}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                label="Street"
                                placeholder="Unit 3, 400 Example Street"
                                value={state?.street}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.street)}
                                helperText={stateError?.street}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            street: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.street}
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Suburb"
                                value={state?.suburb}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.suburb)}
                                helperText={stateError?.suburb}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            suburb: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.suburb}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60 }}>
                        <Col>
                            <TextField
                                label="State"
                                value={state?.state}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.state)}
                                helperText={stateError?.state}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            state: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.state}
                            />
                        </Col>
                        <Col>
                            <TextField
                                label="Postcode"
                                value={state?.postcode}
                                type="text"
                                fullWidth
                                error={Boolean(stateError?.postcode)}
                                helperText={stateError?.postcode}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            postcode: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.postcode}
                            />
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 60, height: 85 }}>
                        <Col>
                            <InputLabel className="mb-0 !leading-5">
                                <span>
                                    Please use the comment section to add
                                    further details if applicable:
                                </span>
                                <ul className="group">
                                    <li className="group-[]:ml-4">
                                        Please indicate the preferred Area/City
                                        code
                                    </li>
                                    <li className="group-[]:ml-4">
                                        Exact Suburb/Town if the requested phone
                                        number pertains to a location different
                                        from the one mentioned in the
                                        company&apos;s address
                                    </li>
                                </ul>
                            </InputLabel>
                        </Col>
                    </Row>
                    <Row style={{ minHeight: 100 }}>
                        <Col>
                            <TextField
                                label="Comments"
                                value={state?.comments}
                                type="text"
                                fullWidth
                                multiline
                                rows={3}
                                rowsMax={3}
                                error={Boolean(stateError?.comments)}
                                helperText={stateError?.comments}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE',
                                        payload: {
                                            comments: e.target.value,
                                        },
                                    });
                                }}
                                data-walkthroughid={requestNewBlock.comments}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    );
};

export default RequestBlockForm;

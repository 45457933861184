import { Card, CardBody, CardFooter, CardHeader, Heading } from 'components/v4';
import AssociationButton from './AssociationButton';

function NoAssociatedAccCard({ id, setState, initialState, darkMode }) {
    return (
        <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
            <CardHeader>
                <Heading
                    fontSize="medium"
                    textColor={darkMode ? 'white' : null}>
                    No Data Found
                </Heading>
            </CardHeader>
            <CardBody textColor={darkMode ? 'white' : null}>
                Currently no associated account found. Please activate one.
            </CardBody>
            <CardFooter justifyContent="center">
                <AssociationButton
                    id={id}
                    setState={setState}
                    initialState={initialState}
                />
            </CardFooter>
        </Card>
    );
}

export default NoAssociatedAccCard;

import { Box, Flex, Heading } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
    AssociatedAccounts,
    MfaTeamsPhoneMobileWizard,
    TpmContent,
    TpmTrialProcess,
    TpmWrapper,
} from './';

import { LoadingSpinnerWithMessage } from '@/lib/loading-screen';
import { classNames } from 'utils/classNames';
import { path } from './constants';

function TeamsPhoneMobileTrial() {
    const { pathname } = useLocation();
    const { SMS_AUTHORIZATION, ASSOCIATED_ACCOUNTS } = path;
    const isAssociatedPage = pathname.includes(path.ASSOCIATED_ACCOUNTS);

    return (
        <TpmWrapper className="flex flex-col">
            <Flex>
                <Heading as="h1" className="mb-4 !font-semibold">
                    Teams Phone Mobile Management
                </Heading>
            </Flex>
            <TpmContent
                className={classNames(
                    'flex flex-col items-center justify-center',
                    isAssociatedPage ? 'h-full' : 'h-tpm',
                )}>
                <Box className="mx-auto my-0">
                    <Box className="mx-auto my-0 max-w-[700px]">
                        <Routes>
                            <Route index element={<TpmTrialProcess />} />
                            <Route
                                path={SMS_AUTHORIZATION}
                                element={<MfaTeamsPhoneMobileWizard />}
                            />
                            <Route
                                path={ASSOCIATED_ACCOUNTS}
                                element={
                                    <Suspense
                                        fallback={
                                            <LoadingSpinnerWithMessage message="Loading Associated Accounts" />
                                        }>
                                        <AssociatedAccounts />
                                    </Suspense>
                                }
                            />
                        </Routes>
                    </Box>
                </Box>
            </TpmContent>
        </TpmWrapper>
    );
}

export default TeamsPhoneMobileTrial;

import { useContext, useDebugValue } from 'react';

import { DashboardMenuContext } from './DashboardMenuContext';

export const useDashboardMenu = () => {
    const context = useContext(DashboardMenuContext);
    useDebugValue(context, 'DashboardMenuContext');
    if (context === undefined) {
        throw new Error('useDashboardMenu must be used within a AuthProvider');
    }

    return context;
};

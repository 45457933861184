import {
    EditIcon,
    ExternalLinkIcon,
    InfoOutlineIcon,
    SettingsIcon,
    SpinnerIcon,
    UnlockIcon,
    WarningTwoIcon,
} from '@chakra-ui/icons';

const iconMap = {
    Edit: EditIcon,
    ExternalLink: ExternalLinkIcon,
    Info: InfoOutlineIcon,
    Settings: SettingsIcon,
    Spinner: SpinnerIcon,
    Unlock: UnlockIcon,
    Warning: WarningTwoIcon,
};

export const IconComponent = ({ icon, ...props }) => {
    const Icon = iconMap[icon];
    return Icon ? <Icon {...props} /> : null;
};

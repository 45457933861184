/**
 * @typedef {'IDLE' | 'PASS' | 'FAIL'} Status
 */

/**
 * @typedef {'idle' | 'submitting' | 'finished' | 'error'} FormState
 */

/**
 * @typedef {'idle' | 'queued' | 'pending' | 'found' | 'notFound'} OperatorStatus
 */

/**
 * @typedef {Object} InitNum
 * @property {string} number - The phone number to be activated or deactivated.
 * @property {Status} status - The status of the phone number.
 */

/**
 * Initial number state with default values.
 * @type {InitNum}
 */
const initNum = { number: '', status: 'IDLE' };

/**
 * @typedef {Object} TpmState
 * @property {string} number - The phone number being processed.
 * @property {string} error - Error message, if any.
 * @property {boolean} touched - Indicates if the form field has been touched.
 * @property {number} lastUpdate - Timestamp of the last update.
 * @property {Status} status - Current status of the process.
 * @property {InitNum[]} activateNumbers - List of numbers to be activated.
 * @property {InitNum[]} deactivateNumbers - List of numbers to be deactivated.
 * @property {FormState} formState - State of the form.
 * @property {OperatorStatus} operatorStatus - State of the operator.
 * @property {number} currentStep - Current step of the process.
 */

/**
 * Initial state for the TPM reducer.
 * @type {TpmState}
 */
export const initialState = {
    number: '',
    status: 'IDLE',
    error: '',
    touched: false,
    lastUpdate: null,
    // NOTE: these for refactoring tpm reducer
    activateNumbers: [initNum],
    deactivateNumbers: [initNum],
    formState: 'idle',
    operatorStatus: 'idle',
    currentStep: 0,
};

// Action types
const SUBMIT_NUMBER = 'tpm/SUBMIT_NUMBER';
const REQUEST_NUMBER = 'tpm/REQUEST_NUMBER';
const RESEND = 'tpm/RESEND';
const NOT_FOUND = 'tpm/NOT_FOUND';
const CONTINUE = 'tpm/CONTINUE';
const FOUND = 'tpm/FOUND';
const RESET = 'tpm/RESET';
const ERROR = 'tpm/ERROR';

export {
    CONTINUE,
    ERROR,
    FOUND,
    NOT_FOUND,
    REQUEST_NUMBER,
    RESEND,
    RESET,
    SUBMIT_NUMBER,
};

import { useEffect, useState } from 'react';
import { Box, FormControl } from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { useSelector } from 'react-redux';

// Components
import { Button, Heading, Input, Checkbox } from '@/components/v4/index';

import iso3166CountryCodes from '../../../../utils/iso3166CountryCodes';
import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import walkthroughId from '@/pages/services/walkthroughIds';

export default function SelectiveSyncForm({
    // Checkbox
    selectedGroupName,
    selectedGroupLocations,
    setSelectedGroupLocations,
    setSelectedGroupName,
    // Fields
    // Other
    serviceWizard,
    isLoading,
    edit,
}) {
    const [isValid, setIsValid] = useState(false);
    const countryOptions = iso3166CountryCodes.map((country) => ({
        label: country.name,
        value: country['alpha-2'],
    }));
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = { ...defaultChakraSelectStyle, ...chakraSelectDark };
    const {
        ufGroupName,
        ufGroupNameInput,
        ufUsageLocation,
        ufUsageLocationTags,
        ufUpdateButton,
    } = walkthroughId.selectiveSync || {};

    /**
     * Validate any field that is enabled (checkbox is checked)
     */
    useEffect(() => {
        if (selectedGroupName && selectedGroupLocations) {
            if (
                serviceWizard.filterGroup?.length &&
                serviceWizard.filterLocations?.length
            ) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else if (selectedGroupName || selectedGroupLocations) {
            if (selectedGroupName) {
                if (serviceWizard.filterGroup?.length) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            }
            if (selectedGroupLocations) {
                if (serviceWizard.filterLocations?.length) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            }
        }
    }, [
        selectedGroupName,
        selectedGroupLocations,
        serviceWizard.filterGroup,
        serviceWizard.filterLocations,
    ]);

    /**
     * Reset the selective sync form whenever the checkboxes are toggled.
     */
    useEffect(() => {
        if (!selectedGroupName && !edit) {
            serviceWizard.setFilterGroup('');
        }
        if (!selectedGroupLocations && !edit) {
            serviceWizard.setFilterLocations(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroupName, selectedGroupLocations]);

    /**
     * Set any edit data.
     */
    useEffect(() => {
        if (serviceWizard.selectiveSyncEditData?.id) {
            if (serviceWizard.selectiveSyncEditData?.filterGroup) {
                setSelectedGroupName(true);
                serviceWizard.setFilterGroup(
                    serviceWizard.selectiveSyncEditData?.filterGroup,
                );
            }
            if (
                serviceWizard.selectiveSyncEditData?.filterLocations[0]?.length
            ) {
                let items = [];
                iso3166CountryCodes.filter((item) => {
                    const matchFn = (element) => element === item['alpha-2'];
                    const match =
                        serviceWizard.selectiveSyncEditData?.filterLocations.some(
                            matchFn,
                        );
                    if (match) {
                        items.push({
                            label: item.name,
                            value: item['alpha-2'],
                        });
                    }
                });

                setSelectedGroupLocations(true);
                serviceWizard.setFilterLocations(items);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceWizard.selectiveSyncEditData]);

    return (
        <Box>
            <Box
                sx={{
                    shadow: 'md',
                    padding: '20px',
                }}
                bg={darkMode ? 'dark.tcap' : 'white'}
                className={'rounded-lg !shadow-lg'}>
                <Heading sx={{ marginBottom: '5px' }}>
                    Select Sync Parameters
                </Heading>

                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '15px',
                        marginTop: '15px',
                    }}
                />

                <Box sx={{ display: 'flex', flexDir: 'column' }}>
                    {/* Group Name */}
                    <FormControl data-walkthroughid={ufGroupName}>
                        <Checkbox
                            isChecked={selectedGroupName}
                            onChange={() =>
                                setSelectedGroupName(!selectedGroupName)
                            }
                            name="selectedGroupName"
                            colorScheme="whatsapp">
                            Group Name{' '}
                        </Checkbox>
                    </FormControl>
                    <i>
                        (The name entered below must exactly match the group
                        name in Active Directory)
                    </i>
                    {selectedGroupName && (
                        <>
                            <FormControl mt={2}>
                                <Input
                                    value={serviceWizard.filterGroup}
                                    onChange={(e) =>
                                        serviceWizard.setFilterGroup(
                                            e.target.value,
                                        )
                                    }
                                    data-walkthroughid={ufGroupNameInput}
                                />
                            </FormControl>
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginBottom: '20px',
                                }}
                            />
                        </>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '5px',
                            marginTop: '5px',
                        }}
                    />

                    {/* Usage Locations */}
                    <FormControl data-walkthroughid={ufUsageLocation}>
                        <Checkbox
                            isChecked={selectedGroupLocations}
                            onChange={() =>
                                setSelectedGroupLocations(
                                    !selectedGroupLocations,
                                )
                            }
                            name="selectedGroupLocations"
                            colorScheme="whatsapp">
                            Usage Locations
                        </Checkbox>
                    </FormControl>

                    {selectedGroupLocations && (
                        <>
                            <FormControl
                                data-walkthroughid={ufUsageLocationTags}>
                                <MultiSelect
                                    defaultValue={
                                        serviceWizard?.filterLocations
                                    }
                                    chakraStyles={chakraStyles}
                                    isMulti
                                    name="usageLocations"
                                    placeholder="- Select regions -"
                                    closeMenuOnSelect={false}
                                    tagVariant="solid"
                                    onChange={(obj) => {
                                        serviceWizard.updateLocations(obj);
                                    }}
                                    options={countryOptions}
                                    // value={countryOptions.filter((obj) =>
                                    //     serviceWizard?.filterLocations?.includes(
                                    //         obj.value,
                                    //     ),
                                    // )}
                                />
                            </FormControl>
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginBottom: '20px',
                                }}
                            />
                        </>
                    )}
                </Box>

                {(selectedGroupName || selectedGroupLocations) && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                marginTop: '20px',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}>
                            {edit && //edit mode
                                serviceWizard?.company?.selectiveSyncPolicy !==
                                    2 && //not required by company
                                serviceWizard?.msServiceAuth?.selectiveSync && ( //selective sync been enabled
                                    <Button
                                        colorScheme="red"
                                        // disabled={!isValid || isLoading}
                                        // sx={{ml: 'auto'}}
                                        onClick={() =>
                                            serviceWizard.onSelectiveSyncDisable()
                                        }>
                                        Disable Selective Sync
                                    </Button>
                                )}
                            <Button
                                isDisabled={!isValid || isLoading}
                                onClick={() =>
                                    edit
                                        ? serviceWizard.onSelectiveSyncEdit()
                                        : serviceWizard.onSelectiveSync()
                                }
                                data-walkthroughid={`${ufUpdateButton}/${edit ? 'Update' : 'Next'}`}>
                                {edit ? 'Update' : 'Next'}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}

/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Select as MultiSelect } from 'chakra-react-select';
import { useQuery } from '@tanstack/react-query';

import { multiSelectDataMapper } from 'utils/utils';
import { axios } from 'services/axios';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    FormLabel,
    Heading,
    LoadingIndicator,
} from 'components/v4';
import { OrderedLists } from 'components/index';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import { classNames } from 'utils/classNames';

import walkthroughIds from '../walkthroughIds';
import { Container } from 'components/v4/Container/Container';

const DEFAULT_DATA = {
    name: 'Direct Routing',
    regions: [],
    voicePolicies: [],
    dialPlans: [],
};

/** Direct Routing component for Edit Services */
export default function DirectRouting({ mode }) {
    const { id: serviceId } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [data, setData] = useState(DEFAULT_DATA);

    const [regions, setRegions] = useState([]);
    const [dialPlans, setDialPlans] = useState([]);
    const [voicePolicies, setVoicePolicies] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { currentCompany: companyID } = useSelector(
        (state) => state.navigationLists,
    );
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        outlineColor: 'gray.300',
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const { state } = useLocation();

    const { serviceProfiles } = walkthroughIds;
    const handleBackButton = () => navigate('/dashboard-services');

    const handleGetData = async () => {
        try {
            const result = await axios.get(`/service/${data?.id}`);
            const voicePolicies = result.data?.voicePolicies?.map(
                multiSelectDataMapper,
            );
            const regions = result.data?.regions?.map(multiSelectDataMapper);
            const dialPlans = result.data?.dialPlans?.map(
                multiSelectDataMapper,
            );

            setData({ ...result.data, voicePolicies, regions, dialPlans });
        } catch (e) {
            setError(e);
        }
    };

    const handleGetDropdownData = async () => {
        try {
            const dpResult = await axios.get(
                `/Services/DirectRouting/Company/${companyID}/DialPlans/Available`,
            );
            setDialPlans(dpResult.data.map(multiSelectDataMapper));
            const regionsResult = await axios.get(
                `/Services/DirectRouting/Company/${companyID}/Profile/${serviceId}/Regions/Available`,
            );
            setRegions(regionsResult.data.map(multiSelectDataMapper));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const { data: directRoutingData } = useQuery({
        queryKey: ['directRoutingData', serviceId],
        queryFn: async () => {
            const res = await axios.get(`/Service/${serviceId}`);
            return res.data;
        },
        onSuccess: async (data) => {
            setData(data);
        },
        enabled: !state,
        refetchOnWindowFocus: false,
        staleTime: 500,
    });

    // this call will set data
    const { data: regionsData } = useQuery({
        queryKey: ['regionsData', companyID, serviceId, mode],
        queryFn: async () => {
            const regionIDQuery = new URLSearchParams({
                RegionIDs: data.regions.map((v) => v.id).join(','),
            });
            const result = await axios.get(
                `/Services/DirectRouting/Company/${companyID}/Profile/${serviceId}/VoicePolicies/Available?${regionIDQuery}`,
            );

            return result.data;
        },
        onSuccess: (data) => {
            setVoicePolicies(data.map(multiSelectDataMapper));
            if (mode === 'edit' && data?.id) {
                handleGetData();
            }
            // handleGetDropdownData();
        },
        enabled: data.regions && data.regions.length > 0,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        handleGetDropdownData();
    }, []);

    useEffect(() => {
        if (state?.id) {
            setData(state);
        }
    }, [state]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...data,
            companyID,
        };
        let result = {};
        const endpoint = '/Service/DirectRouting';
        setIsLoading(true);
        try {
            switch (mode) {
                case 'add':
                    result = await axios.post(endpoint, payload).then(() => {
                        navigate('/dashboard-services');
                    });
                    break;
                case 'edit':
                    result = await axios.put(endpoint, payload).then(() => {
                        toast({
                            status: 'success',
                            title: 'Service Updated',
                            description: 'Direct Routing has been updated.',
                            isClosable: true,
                            duration: 3000,
                        });
                        navigate('/dashboard-services');
                    });
                    break;
            }
            handleBackButton();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeValue = (type, options) => {
        setData({ ...data, [type]: options });
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }
    return (
        <Container>
            <Heading fontSize="3xl" as="h2" mb={8}>
                Editing {data.name}
            </Heading>

            {error && (
                <>
                    <Box sx={{ margin: '20px 0' }}></Box>
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>
                            {error?.response?.data?.message ||
                                'An error occured. please try again later.'}
                        </AlertDescription>
                    </Alert>
                </>
            )}
            <Box
                onSubmit={handleSubmit}
                as="form"
                sx={{
                    padding: '10px 0',
                }}>
                {/* Regions */}
                <OrderedLists
                    setState={(v) => {
                        setData((prev) => ({
                            ...prev,
                            ...v,
                        }));
                    }}
                    options={regions}
                    modalState={data}
                    placeholder="- Select Regions -"
                    dataWalkthroughId={serviceProfiles.ufRegionTable}
                />

                <Box sx={{ margin: '20px 0' }}></Box>

                {/* Voice Policies */}
                <Box data-walkthroughid={serviceProfiles.ufVoicePoliciesSelect}>
                    <FormLabel>Voice Policies</FormLabel>
                    <MultiSelect
                        chakraStyles={chakraStyles}
                        isMulti
                        name="voicePolicies"
                        placeholder="- Select Voice Policies -"
                        closeMenuOnSelect={false}
                        tagVariant="solid"
                        onChange={(selectedOptions) =>
                            handleChangeValue('voicePolicies', selectedOptions)
                        }
                        options={voicePolicies}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={data.voicePolicies}
                    />
                </Box>

                <Box sx={{ margin: '20px 0' }}></Box>

                {/* Dial Plans */}
                <Box data-walkthroughid={serviceProfiles.ufDialPlansSelect}>
                    <FormLabel>Dial Plans</FormLabel>
                    <MultiSelect
                        chakraStyles={chakraStyles}
                        isMulti
                        name="dialPlans"
                        placeholder="- Select Dial Plans -"
                        closeMenuOnSelect={false}
                        tagVariant="solid"
                        onChange={(selectedOptions) =>
                            handleChangeValue('dialPlans', selectedOptions)
                        }
                        options={dialPlans}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={data.dialPlans}
                    />
                </Box>

                <Box mt={12} aria-label="spacing box" />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '170px',
                        margin: '0 auto',
                    }}>
                    <Button
                        variant="outline"
                        w={{ base: '100%', md: '80px' }}
                        className={classNames(
                            darkMode ? 'bg-white hover:opacity-70' : null,
                        )}
                        onClick={handleBackButton}
                        data-walkthroughid={serviceProfiles.ufCloseButton}>
                        Close
                    </Button>
                    <Button
                        ml="1rem"
                        disabled={isLoading}
                        w={{ base: '100%', md: '80px' }}
                        type="submit"
                        data-walkthroughid={serviceProfiles.ufUpdateButton}>
                        {mode === 'add' ? 'Add' : 'Update'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

//external imports
import Axios from 'axios';
import store from '../store/store';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

//local imports
import appConfig from '../config.json';
import { default as axiosRateLimit } from 'https://cdn.jsdelivr.net/npm/axios-rate-limit@1.3.0/+esm';

Axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;

// Obtain the fresh token each time the function is called
function getAccessToken() {
    const token = store.getState().login?.token;
    return token; //TODO CHANGE
}

function getRefreshToken() {
    const refreshToken = store.getState().login?.refreshToken;
    return refreshToken;
}

function getExpiration() {
    const expiration = store.getState().login?.expiration;
    return expiration;
}

function getRefreshExpiration() {
    const refreshExpiration = store.getState().login?.refreshExpiration;
    return refreshExpiration;
}

function getCurrentScope() {
    const currentScope = store.getState().login?.currentScope;
    return currentScope ?? 20;
}

function getHighestScope() {
    const requiredScope = store.getState().login?.requiredScope;
    return requiredScope ?? 20;
}

function getModalShow() {
    const modalShow = store.getState().modal?.show;
    return modalShow;
}

function getBreadcrumbView() {
    const breadcrumb = store.getState().view?.breadcrumb;
    return breadcrumb;
}

function getChildTable() {
    const child = store.getState().table?.child;
    return child;
}

/** @type {const} 90 seconds */
export const TOKEN_EXPIRY_THRESHOLD = 1000 * 90;

const addScope = (config) => {
    config.headers.common[appConfig.scope.name] = appConfig.scope.company;
    if (config?.url?.includes('null')) {
        return Promise.reject({
            name: 'No Partner / Company / Wholesaler Selected',
            message: 'Please select a wholesaler and a partner / company.',
        });
    }
    // renew , auth doesnt need auth header:
    if (['/renew', '/auth'].includes(config.url)) {
        config.headers.common[appConfig.scope.name] = appConfig.scope.company;
        return config;
    }

    config.headers['authorization'] = `bearer ${getAccessToken()}`;
    //request /partners/all
    if (config?.url === '/partners/all') {
        config.headers.common[appConfig.scope.name] = appConfig.scope.global;
        return config;
    }
    //request /partners/
    if (config?.url?.includes('/partners/')) {
        config.headers.common[appConfig.scope.name] =
            appConfig.scope.wholesaler;
        return config;
    }

    if (getChildTable() || getBreadcrumbView() !== 'all' || getModalShow()) {
        config.headers.common[appConfig.scope.name] = getHighestScope();
        return config;
    }
    //for the endpoints that doesnt fall into company/partner/wholesalers/global admins
    //but still needs auth headers
    if (
        ['/CompanyNavigationList', '/wholesalernavigationlist'].some((v) =>
            config.url.includes(v),
        )
    ) {
        config.headers.common[appConfig.scope.name] = appConfig.scope.company;
        return config;
    }

    config.headers.common[appConfig.scope.name] = getCurrentScope();

    return config;
};

Axios.interceptors.request.use(
    (config) => {
        addSideEffect(config);
        return addScope(config);
    },
    (e) => {
        return Promise.reject(e);
    },
);

export const checkToken = () => {
    var refreshToken = getRefreshToken();
    var token = getAccessToken();
    var expiration = getExpiration();
    var refreshExpiration = getRefreshExpiration();
    var now = new Date();
    if (
        refreshToken === null ||
        token === null ||
        expiration === null ||
        refreshExpiration === null ||
        (now >= Date.parse(expiration) && now >= Date.parse(refreshExpiration)) //token has expired, but refreshToken has expired too
    ) {
        return 'RELOGIN';
    } else if (
        now < Date.parse(expiration) &&
        now < Date.parse(refreshExpiration)
    ) {
        return 'VALID';
    } else if (
        Date.now() >= Date.parse(expiration) - TOKEN_EXPIRY_THRESHOLD &&
        Date.now() < Date.parse(refreshExpiration)
    ) {
        console.log('refresh token release');
        return 'REFRESH';
    }
    return 'UNKNOWN';
};

const refreshAuthLogic = async (failedRequest) => {
    //delete header first (refreshing doesnt need auth heder)
    console.log('refreshing logic starts', { failedRequest });

    delete Axios.defaults.headers['authorization'];

    switch (checkToken()) {
        case 'RELOGIN':
            sessionStorage.clear();
            localStorage.clear();
            store.dispatch({ type: 'LOGOUT' });
            store.dispatch({ type: 'CLEAR_TOKEN' });
            return Promise.reject(failedRequest);
        case 'VALID':
            store.dispatch({ type: 'ERROR_401' }); //indicate something error.
            return Promise.reject(failedRequest);
        case 'UNKNOWN':
            store.dispatch({ type: 'ERROR_401' }); //indicate something error.
            return Promise.reject(failedRequest);
        default:
            //default is for refresh case
            console.log('refreshing calls renew');
            return new Promise((resolve, reject) => {
                Axios.post('/renew', {
                    token: getAccessToken(),
                    refreshToken: getRefreshToken(),
                })
                    .then((response) => {
                        const {
                            refreshExpiration,
                            refreshToken,
                            expiration,
                            token,
                        } = response.data;
                        console.log('resolve', { response });

                        failedRequest.response.config = {
                            ...failedRequest.config,
                        };
                        // failedRequest.response.config.headers.common[appConfig.scope.name] = getHighestScope();
                        failedRequest.response.config.headers['authorization'] =
                            'bearer ' + token;
                        Axios.defaults.headers.authorization =
                            'bearer ' + token;
                        store.dispatch({
                            type: 'RELOGIN_SUCCESS',
                            payload: {
                                refreshExpiration,
                                refreshToken,
                                expiration,
                                token,
                            },
                        });
                        resolve();
                    })
                    .catch((e) => {
                        sessionStorage.clear();
                        localStorage.clear();
                        console.log('reject', { e });
                        store.dispatch({ type: 'LOGOUT' });
                        store.dispatch({ type: 'CLEAR_TOKEN' });
                        reject({
                            name: 'Failed to refresh login token',
                            message: 'Please relogin.',
                        });
                    });
            });

        // const retryRequest = await Axios.request(failedRequest.config);
        // console.log('retryRequest success', {retryRequest});
    }
};

const addSideEffect = (config) => {
    const { url: sourceURL } = config;
    if (sourceURL === '/company/' || sourceURL === '/partner/') {
        store.dispatch({
            type: 'CHANGE_COMPANIES_AND_PARTNERS_LOADING',
            payload: true,
        });
    } else if (sourceURL === '/wholesaler/') {
        store.dispatch({
            type: 'CHANGE_WHOLESALERS_LOADING',
            payload: true,
        });
    }
};

createAuthRefreshInterceptor(Axios, refreshAuthLogic, {
    shouldRefresh: (e) => {
        const url = e.request.responseURL || '';
        console.log('should refresh run error', { e });
        store.dispatch({
            type: 'CHANGE_MODAL_BACKEND_ERROR',
            payload: e?.response?.data?.message || e?.response.data.title,
        });
        store.dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: e?.response,
        });

        if (url.toLowerCase().includes('/renew')) {
            store.dispatch({ type: 'LOGOUT' });
            store.dispatch({ type: 'CLEAR_TOKEN' });
            return false;
        }

        return true;
    },
});
export const forceRefreshToken = () => {
    return new Promise((resolve, reject) => {
        var refreshToken = getRefreshToken();
        var token = getAccessToken();
        Axios.post('/renew', { token, refreshToken })
            .then((res) => {
                const { refreshExpiration, refreshToken, expiration, token } =
                    res.data;

                Axios.defaults.headers.authorization = 'bearer ' + token;
                Axios.defaults.headers[appConfig.scope.name] =
                    appConfig.scope.company;
                store.dispatch({
                    type: 'RELOGIN_SUCCESS',
                    payload: {
                        refreshExpiration,
                        refreshToken,
                        expiration,
                        token,
                    },
                });
                resolve(res);
            })
            .catch((e) => {
                console.log('forceRefreshToken', e);
                reject({
                    name: 'Failed to refresh login token',
                    message: 'Please relogin.',
                });
            });
    });
};

/**
 * @type {import('axios').AxiosInstance}
 */
export const axios = axiosRateLimit(Axios, {
    maxRequests: appConfig.maxRequests,
    perMilliseconds: appConfig.perMilliseconds,
});

import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';

import { useServicesDashboard } from 'context/ServicesDashboardContext';

import Text from 'components/v4/Text';
import { IconComponent } from '../IconComponent';

export default function OperatorConnectStatus({ serviceObj, handleActions }) {
    const { darkMode } = useSelector((state) => state.settings);
    const { OperatorConnect: cardStatus } = useServicesDashboard();
    const { handleGrantAccess } = handleActions;
    const handleClick =
        cardStatus?.actionIconTooltip === 'Grant Access'
            ? handleGrantAccess
            : null;

    if (!serviceObj) return <Text>...</Text>;

    if (cardStatus?.tooltipIcon) {
        return (
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <Text className="ml-1 truncate">{cardStatus.statusLabel}</Text>

                <span className="mx-1 inline-flex flex-shrink-0 self-center">
                    <Tooltip
                        label={
                            cardStatus?.tooltip ||
                            cardStatus?.statusDisplay?.statusDisplayExtended
                        }
                        hasArrow
                        placement="bottom">
                        <IconButton
                            icon={
                                <IconComponent
                                    icon={cardStatus?.tooltipIcon}
                                    color={darkMode ? 'white' : 'black'}
                                    className={
                                        cardStatus?.tooltipIcon === 'Spinner'
                                            ? 'animate-spin'
                                            : ''
                                    }
                                />
                            }
                            size="xs"
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        />
                    </Tooltip>
                </span>

                {cardStatus?.actionIcon && serviceObj?.authPolicy !== 0 && (
                    <span className="my-auto inline-flex flex-shrink-0 self-center">
                        <Tooltip
                            label={cardStatus?.actionIconTooltip}
                            hasArrow
                            placement="bottom">
                            <IconButton
                                icon={
                                    <IconComponent
                                        icon={cardStatus?.actionIcon}
                                        color={darkMode ? 'white' : 'black'}
                                    />
                                }
                                size="xs"
                                onClick={handleClick}
                                bg="transparent"
                                _hover={{ bg: 'transparent' }}
                            />
                        </Tooltip>
                    </span>
                )}
            </Box>
        );
    }

    return <Text>{cardStatus?.statusLabel}</Text>;
}

import {
    Button as ChakraButton,
    HStack,
    Icon,
    Link,
    Tooltip,
    forwardRef,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link as RouterLink, useNavigate } from 'react-router-dom';

import { AnimatedTextWord } from './AnimatedTextWord';

import { checkHash } from '@/store/middlewares/modalChangesMiddleware';
import { _active, _focus, _hover } from './constants';

/** @type React.ForwardRefRenderFunction<HTMLButtonElement, import('@chakra-ui/react').ButtonProps> */
const Button = forwardRef((props, ref) => {
    return (
        <ChakraButton
            justifyContent="space-between"
            width="full"
            ref={ref}
            {...props}
        />
    );
});
const SidebarItem = ({
    item,
    parentPath,
    index,
    isMenuCollapse,
    colorMode,
}) => {
    const { path, permissionName, scope } = item;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { show, state, hash } = useSelector((state) => {
        return { ...state.modal };
    });

    const { apiDocReader } = useSelector((state) => {
        return { ...state.login };
    });
    const { darkMode, mixedMode } = useSelector((state) => state.settings);
    const currentPaths = window.location.pathname.split('/');
    const currentSubPath = currentPaths.length >= 2 && currentPaths[2];
    const isDirectingToSamePage = `/${currentSubPath}` === path;
    const onClick = () => {
        console.log('navigate to', `${path} - index: ${index}`);

        if (item.permissionName === 'api') {
            return window.open('/docs/index.html', '_blank');
        }

        if (state?.noNeedPrompt) {
            dispatch({
                type: 'REDIRECT',
                payload: {
                    pathname: '/' + parentPath + '/' + permissionName,
                    scope: scope ?? 20,
                },
            });

            navigate(
                isDirectingToSamePage ? `${currentSubPath}` : `/${path}` || '',
            );
            return;
        }

        // changes detected need to show window alert before navigation
        if (show && !checkHash({ originalHash: hash, currentObject: state })) {
            const value = window.confirm(
                'Are you sure you would like to switch to another menu? All unsaved changes will be lost.',
            );
            if (!value) {
                // do nothing
                return;
            }
        }

        dispatch({
            type: 'REDIRECT',
            payload: {
                pathname: '/' + parentPath + '/' + permissionName,
                scope: scope ?? 20,
            },
        });

        navigate(
            isDirectingToSamePage
                ? `/reload/${currentSubPath}`
                : `${path}` || '',
        );
    };

    if (item.label === 'API') {
        return (
            apiDocReader && (
                <Link
                    as={RouterLink}
                    to={{ pathname: '/docs/index.html' }}
                    target="_blank"
                    _hover={{ color: 'inherit', textDecoration: 'none' }}
                    _focus={{ boxShadow: 'none' }}>
                    <Tooltip
                        label={isMenuCollapse ? item.label : null}
                        hasArrow
                        placement="right-end">
                        <Button
                            bg="none"
                            _focus={{ bg: 'none' }}
                            _hover={colorMode[_hover]}
                            _active={{ bg: 'none' }}
                            size="sm"
                            onClick={onClick}
                            fontWeight={400}
                            paddingInline={isMenuCollapse ? '2' : null}
                            color={darkMode || mixedMode ? 'white' : 'black'}>
                            <HStack spacing="3" aria-label="children route">
                                <Icon
                                    fontSize="lg"
                                    ml={isMenuCollapse ? '1.35rem' : 5}
                                    mr={2}
                                    as={item.Icon}
                                />
                                {isMenuCollapse ? null : (
                                    <AnimatedTextWord text={item.label} />
                                )}
                                {!isMenuCollapse && (
                                    <FaExternalLinkAlt
                                        fontSize="small"
                                        style={{
                                            marginLeft: 10,
                                            height: 10,
                                            width: 10,
                                        }}
                                        className="ml-0 inline-block"
                                    />
                                )}
                            </HStack>
                        </Button>
                    </Tooltip>
                </Link>
            )
        );
    }

    return (
        <Link
            as={NavLink}
            to={
                isDirectingToSamePage
                    ? `/reload/${currentSubPath}`
                    : item.path || ''
            }
            _hover={{ color: 'inherit' }}
            style={{ marginTop: 0 }}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}>
            {({ isActive }) => {
                return (
                    <Tooltip
                        label={isMenuCollapse ? item.label : null}
                        hasArrow
                        placement="right-end">
                        <Button
                            _focus={colorMode[_focus]}
                            _active={colorMode[_active]}
                            _hover={
                                isActive
                                    ? colorMode[_active]
                                    : colorMode[_hover]
                            }
                            fontWeight={isActive ? 'bold' : ''}
                            bg={
                                path.includes(`/${currentSubPath}`)
                                    ? colorMode[_active].bg
                                    : 'none'
                            }
                            size="sm"
                            onClick={onClick}
                            paddingInline={isMenuCollapse ? '2' : null}
                            color={darkMode || mixedMode ? 'white' : 'black'}>
                            <HStack spacing="3" aria-label="children route">
                                <Icon
                                    fontSize="lg"
                                    ml={isMenuCollapse ? '1.35rem' : 5}
                                    mr={2}
                                    as={item.Icon}
                                />
                                {isMenuCollapse ? null : (
                                    <AnimatedTextWord text={item.label} />
                                )}
                            </HStack>
                        </Button>
                    </Tooltip>
                );
            }}
        </Link>
    );
};

export default SidebarItem;

import { Icon } from '@chakra-ui/react';
import {
    FaCircleCheck,
    FaCircleQuestion,
    FaCircleXmark,
} from 'react-icons/fa6';

const StatusMap = {
    IDLE: {
        ariaLabel: 'IDLE',
        statusColor: 'transparent',
        statusIcon: null,
    },
    LOADING: {
        ariaLabel: 'LOADING',
        statusColor: 'gray.500',
        statusIcon: FaCircleQuestion,
        pulseClass: 'animate-pulse',
    },
    PASS: {
        ariaLabel: 'PASS',
        statusColor: 'green.500',
        statusIcon: FaCircleCheck,
    },
    FAIL: {
        ariaLabel: 'FAIL',
        statusColor: 'red.500',
        statusIcon: FaCircleXmark,
    },
};

function Status({ info, verifiedStatus, type }) {
    const value = info.getValue();
    const isUnassign = info.row.original?.action === 'UNASSIGN';
    const isPass = value === 'PASS';
    const isIdle = value === 'IDLE' || value === '';
    const isLoading = verifiedStatus === 'loading';
    let status = StatusMap.IDLE;

    if (isLoading) {
        status = StatusMap.LOADING;
    } else if (isPass) {
        status = StatusMap.PASS;
    } else if (!isIdle) {
        status = StatusMap.FAIL;
    }

    return (
        <div
        // className={
        //     type === 'deactivate' && value === 'FAIL' ? 'opacity-70' : null
        // }
        >
            <Icon
                bg="transparent"
                boxSize="18px"
                ml={3}
                className={status.pulseClass || ''}
                aria-label={status.ariaLabel}
                color={status.statusColor}
                as={status.statusIcon}
            />
            {type === 'deactivate' && isUnassign && (
                <span className="ml-2">Assigned to User</span>
            )}
        </div>
    );
}

export default Status;

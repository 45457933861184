import { CheckIcon } from '@chakra-ui/icons';
import { Button, IconButton, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '@/components/v4';
import ImportWrapper from './ImportWrapper';

function ImportSuccess({ onClick }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <ImportWrapper>
            <Card
                w={300}
                alignItems="center"
                bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                <CardHeader>
                    <Text
                        className="font-medium"
                        textAlign="center"
                        color="green.500">
                        Activation Complete
                    </Text>
                </CardHeader>
                <CardBody py={0} textAlign="center">
                    <IconButton
                        isRound={true}
                        variant="solid"
                        colorScheme="whatsapp"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<CheckIcon />}
                        cursor="unset"
                        _focus={{
                            boxShadow: 'none',
                        }}
                        _hover={{
                            cursor: 'unset',
                        }}
                    />
                </CardBody>
                <CardFooter justifyContent="center">
                    <Button onClick={onClick}>Start Over</Button>
                </CardFooter>
            </Card>
        </ImportWrapper>
    );
}

export default ImportSuccess;

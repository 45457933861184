import { useDebugValue } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const formatDebugValue = ({ location }) =>
    `${
        location.state?.from
            ? `navigate from: ${location.state?.from}`
            : `current location: ${location.pathname}`
    }`;

/**
 * A custom hook to track current location and past location of a user, also reset modal when user navigates to a new page to prevent stale data from modal
 * @typedef {Object} NavigateLocationType
 * @property {(page: string, options?: import('react-router-dom').NavigateOptions) => void} handleNavigateLocation - a modified navigate function that includes a from location and reset modal
 * @returns {NavigateLocationType}  - a modified navigate function
 */
export function useNavigateLocation() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const _options = {
        state: { from: location?.pathname },
    };

    /**
     * @param {string} page - The page that user navigates to
     * @param {import('react-router-dom').NavigateOptions} options - The state to pass to the new location
     * @property {boolean} options.state.isModalPage - A flag to reset modal when navigating to a new page from TableAndModal
     * */
    function handleNavigateLocation(page, options = _options) {
        const { isModalPage = true } = options.state;
        if (isModalPage) {
            dispatch({ type: 'RESET_MODAL' });
        }
        navigate(page, options);
    }

    useDebugValue({ location }, formatDebugValue);

    return { handleNavigateLocation };
}

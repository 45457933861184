import { Box, Wrap } from '@chakra-ui/react';
import { Chip } from './Chip';

export const ChipList = ({
    inputs = [],
    onCloseClick,
    isDisabled = false,
    ...rest
}) => {
    return (
        <Box>
            {inputs.length > 0 && (
                <Wrap
                    spacing={1}
                    mb={1}
                    p={1}
                    {...rest}
                    border={'2px'}
                    borderColor={'#3182ce'}
                    borderRadius={'md'}>
                    {inputs.map((input, index) => (
                        <Chip
                            input={input}
                            isDisabled={isDisabled}
                            key={index}
                            onCloseClick={onCloseClick}
                        />
                    ))}
                </Wrap>
            )}
        </Box>
    );
};

/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
    useNavigate,
    useLocation,
    useParams,
    Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, useToast, Stack } from '@chakra-ui/react';
import {
    Select as MultiSelect,
    Select as ChakraSelect,
} from 'chakra-react-select';
import { useQuery } from '@tanstack/react-query';

import { axios } from '@/services/axios';
import useFieldValidation from 'hooks/useFieldValidation';
import { query, multiSelectDataMapper } from 'utils/utils';
import { classNames } from 'utils/classNames';

import getService from 'API/Service/getService';

// import FieldValidation from 'components/v4/FieldValidation';
import {
    Button,
    LoadingIndicator,
    FormControl,
    FormLabel,
    Input,
    Heading,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@/components/v4';
import { Container } from 'components/v4/Container/Container';

import { createSbcaasValidationSchema } from '@/pages/services/provisionedservices/consts';

import { chakraSelectDarkMode, defaultChakraSelectStyle } from '@/constants';
import walkthroughIds from 'pages/services/walkthroughIds';
import { maxRedundancyOptions, toastSuccess } from './constants';

const { serviceProfiles } = walkthroughIds;
const authPolicyOptions = [
    { label: 'Disabled', value: 0 },
    { label: 'Enabled', value: 1 },
    { label: 'Required', value: 2 },
];

export function OC() {
    const navigate = useNavigate();
    const { id: serviceId } = useParams();
    const { state } = useLocation();
    const toast = useToast();
    // Options
    const [name, setName] = useState(null);
    const [authPolicy, setAuthPolicy] = useState(null);
    const [callingProfiles, setCallingProfiles] = useState(null);
    const [regions, setRegions] = useState(null);
    const [voicePolicies, setVoicePolicies] = useState(null);
    const [dialPlans, setDialPlans] = useState(null);
    const [operatorProfile, setOperatorProfile] = useState(null);
    const [dropdowns, setDropdowns] = useState({});
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    // Flags
    const [isLoading, setIsLoading] = useState(false);

    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);
    const chakraStyles = {
        outlineColor: 'gray.300',
        ...defaultChakraSelectStyle,
        ...chakraSelectDark,
    };
    const companyID = useSelector(
        (state) => state.navigationLists.currentCompany,
    );

    const { data: OcData } = useQuery({
        queryKey: ['service', serviceId],
        queryFn: async () => {
            const res = await getService(serviceId);
            console.log('service data', res.data);
            return res.data;
        },
        onSuccess: (data) => {
            setData(data);
        },
        enabled: !state || !!serviceId,
    });

    function createOcFields(params) {
        let ocParams = {};
        const {
            companyID,
            operatorProfileId,
            authPolicy,
            id,
            name,
            dialPlans,
            voicePolicies,
            regions,
            callingProfiles,
        } = params;

        if (id) {
            ocParams.id = id;
        }
        if (name) {
            ocParams.name = name;
        }
        if (companyID) {
            ocParams.companyID = companyID;
        }
        if (operatorProfileId) {
            ocParams.operatorProfileID = operatorProfileId;
        }
        if (authPolicy) {
            ocParams.authPolicy = authPolicy;
        }
        if (dialPlans?.length) {
            ocParams.dialPlans = dialPlans.map((dp) => ({
                id: dp.value,
                name: dp.label,
            }));
        }
        if (voicePolicies?.length) {
            ocParams.voicePolicies = voicePolicies.map((vp) => ({
                id: vp.value,
                name: vp.label,
            }));
        }
        if (regions?.length) {
            ocParams.regions = regions.map((re) => ({
                id: re.value,
                name: re.label,
            }));
        }
        if (callingProfiles?.length) {
            ocParams.callingProfiles = callingProfiles.map((cp) => ({
                id: cp.value,
                name: cp.label,
            }));
        }

        return ocParams;
    }

    function updateOcService(e) {
        setIsLoading(true);

        const params = {
            name,
            companyID: companyID,
            authPolicy,
            id: data.id,
            operatorProfileId: operatorProfile,
            dialPlans,
            voicePolicies,
            regions,
            callingProfiles,
        };

        const ocParams = createOcFields(params);

        axios
            .put('/Service/OperatorConnect', ocParams)
            .then((response) => {
                navigate('/dashboard-services');
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getDropdownData = async () => {
        const _callingProfiles = data.callingProfiles.map(
            multiSelectDataMapper,
        );
        const _dialPlans = data.dialPlans.map(multiSelectDataMapper);
        const _regions = data.regions.map(multiSelectDataMapper);
        const _voicePolicies = data.voicePolicies.map(multiSelectDataMapper);

        setName(data.name);
        setCallingProfiles(_callingProfiles);
        setDialPlans(_dialPlans);
        setRegions(_regions);
        setVoicePolicies(_voicePolicies);
        setAuthPolicy(data.authPolicy);
        setOperatorProfile(data.operatorProfile.id);

        try {
            const opRes = await axios.get(
                `/Services/OperatorConnect/Company/${companyID}/OperatorProfiles/Available`,
            );
            const dpRes = await axios.get(
                `/Services/OperatorConnect/Company/${companyID}/DialPlans/Available`,
            );

            setDropdowns((prev) => ({
                ...prev,
                operatorProfile: opRes.data.map(multiSelectDataMapper),
                dialPlans: dpRes.data.map(multiSelectDataMapper),
            }));
        } catch (e) {
            console.log('error fetch data', { e });
        }
    };

    const getRegionsAndVPDropdown = async () => {
        try {
            const cpRes = await axios.get(
                `/Services/OperatorConnect/Company/${companyID}/Profile/${operatorProfile}/CallingProfiles/Available`,
            );
            setDropdowns((prev) => ({
                ...prev,

                callingProfiles: cpRes.data.map(multiSelectDataMapper),
            }));
        } catch (e) {
            console.log('error fetch data', { e });
        }
    };

    useEffect(() => {
        if (data) {
            getDropdownData();
        }
    }, [data]);

    useEffect(() => {
        if (state?.id) {
            setData(state);
        }
    }, [state]);

    useEffect(() => {
        getRegionsAndVPDropdown();
    }, [operatorProfile]);

    useEffect(() => {
        if (operatorProfile && callingProfiles?.length > 0) {
            axios
                .get(
                    `/Services/OperatorConnect/Company/${companyID}/Profile/${operatorProfile}/Regions/Available?CallingProfileIDs=${query(
                        callingProfiles,
                    )}`,
                )
                .then((regRes) => {
                    setDropdowns((prev) => ({
                        ...prev,

                        regions: regRes.data.map(multiSelectDataMapper),
                    }));
                });
        }
    }, [operatorProfile, callingProfiles]);

    useEffect(() => {
        if (
            operatorProfile &&
            callingProfiles?.length > 0 &&
            regions?.length > 0
        ) {
            axios
                .get(
                    `/Services/OperatorConnect/Company/${companyID}/Profile/${operatorProfile}/VoicePolicies/Available?CallingProfileIDs=${query(
                        callingProfiles,
                    )}&RegionIDs=${query(regions)}`,
                )
                .then((vpRes) => {
                    setDropdowns((prev) => ({
                        ...prev,
                        voicePolicies: vpRes.data.map(multiSelectDataMapper),
                    }));
                });
        }
    }, [operatorProfile, callingProfiles, regions]);

    if (!serviceId || !state) {
        return <Navigate to="/dashboard-services" />;
    }

    /**
     * Loading.
     */
    if (
        callingProfiles === null ||
        regions === null ||
        voicePolicies === null ||
        dialPlans === null ||
        authPolicy === null
    ) {
        return <LoadingIndicator />;
    }

    return (
        <Container>
            <Heading fontSize="3xl" as="h2" sx={{ marginBottom: '32px' }}>
                Editing {data.name}
            </Heading>
            {error && (
                <Alert status="error" sx={{ marginTop: '1rem' }}>
                    <AlertIcon />
                    <AlertDescription>
                        {error?.response?.data?.message ||
                            'An error occured. Please try again later.'}
                    </AlertDescription>
                </Alert>
            )}
            <Box sx={{ padding: '10px 0' }}>
                <Stack space="30px">
                    {/* Calling Profiles */}
                    <FormControl
                        data-walkthroughid={
                            serviceProfiles.ufCallingProfilesSelect
                        }>
                        <FormLabel>Calling Profiles</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="colors"
                            placeholder="- Select Calling Profiles -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={(e) => setCallingProfiles(e)}
                            options={dropdowns.callingProfiles}
                            defaultValue={callingProfiles}
                        />
                    </FormControl>

                    {/* Regions */}
                    <FormControl
                        data-walkthroughid={serviceProfiles.ufRegionsSelect}>
                        <FormLabel>Regions</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="colors"
                            placeholder="- Select Regions -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={(e) => setRegions(e)}
                            options={dropdowns.regions}
                            defaultValue={regions}
                        />
                    </FormControl>

                    {/* Voice Policies */}
                    <FormControl
                        data-walkthroughid={
                            serviceProfiles.ufVoicePoliciesSelect
                        }>
                        <FormLabel>Voice Policies</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="colors"
                            placeholder="- Select Voice Policies -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={(e) => {
                                setVoicePolicies(e);
                            }}
                            options={dropdowns.voicePolicies}
                            defaultValue={voicePolicies}
                        />
                    </FormControl>

                    {/* Dial Plans */}
                    <FormControl
                        data-walkthroughid={serviceProfiles.ufDialPlansSelect}>
                        <FormLabel>Dial Plans</FormLabel>
                        <MultiSelect
                            chakraStyles={chakraStyles}
                            isMulti
                            name="colors"
                            placeholder="- Select Dial Plans -"
                            closeMenuOnSelect={false}
                            tagVariant="solid"
                            onChange={(e) => setDialPlans(e)}
                            options={dropdowns.dialPlans}
                            defaultValue={dialPlans}
                        />
                    </FormControl>

                    {/* Auth Policy */}
                    <FormControl
                        data-walkthroughid={serviceProfiles.ufAuthPolicySelect}>
                        <FormLabel>Auth Policy</FormLabel>
                        {/* <Dropdown
                            options={[
                                { label: 'Disabled', value: 0 },
                                { label: 'Enabled', value: 1 },
                                { label: 'Required', value: 2 },
                            ]}
                            value={authPolicy}
                            onChange={(e) => setAuthPolicy(e.target.value)}
                            sx={{ borderColor: 'gray.300' }}
                        /> */}
                        <ChakraSelect
                            options={authPolicyOptions}
                            value={authPolicyOptions[authPolicy]}
                            onChange={(e) => setAuthPolicy(e.value)}
                            chakraStyles={chakraStyles}
                            selectedOptionColor="brand"
                        />
                    </FormControl>
                    <Box sx={{ margin: '20px 0' }} />
                </Stack>

                <Box mt={8} aria-label="spacing box" />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '170px',
                        margin: '0 auto',
                    }}>
                    <Button
                        variant="outline"
                        w={{ base: '100%', md: '80px' }}
                        className={classNames(
                            darkMode ? 'bg-white hover:opacity-70' : null,
                        )}
                        // sx={{ background: 'gray.300' }}
                        onClick={() => navigate('/dashboard-services')}
                        data-walkthroughid={serviceProfiles.ufCloseButton}>
                        Close
                    </Button>
                    <Button
                        ml="1rem"
                        w={{ base: '100%', md: '80px' }}
                        onClick={() => updateOcService()}
                        disabled={isLoading}
                        type="submit"
                        data-walkthroughid={serviceProfiles.ufUpdateButton}>
                        Update
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

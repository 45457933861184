import './typedef';

export const defaultChakraSelectStyle = {
    dropdownIndicator: (provided, { selectProps }) => {
        return {
            ...provided,
            bg: 'transparent',
            px: 2,
            cursor: 'pointer',
            '> svg': {
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                transitionDuration: '.4s',
            },
        };
    },
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
};

/**
 * @type {import('chakra-react-select').ChakraStylesConfig} chakraSelectDarkMode
 * @param {string} darkMode
 */
export const chakraSelectDarkMode = (darkMode) => ({
    menuList: (provided, { selectProps }) => ({
        ...provided,
        ...scrollBarStyle(darkMode),
        background: `${
            darkMode && selectProps.onFocus ? 'dark.tcap-weak' : 'white'
        }`,
        borderColor: `${darkMode ? 'dark.tcap-borderGray' : 'inherit'}`,
        color: `${darkMode ? 'white' : 'black'}`,
    }),
    control: (provided) => ({
        ...provided,
        color: `${darkMode ? 'dark.textDark' : 'inherit'}`,
        borderColor: `${darkMode ? 'dark.tcap-borderGray' : 'inherit'}`,
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        backgroundColor:
            darkMode && isFocused
                ? 'rgba(255, 255, 255, 0.16)!important'
                : null,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
    }),
    valueContainer: (provided) => ({
        ...provided,
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
    }),
    inputContainer: (provided) => ({
        ...provided,
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
        color: `${darkMode ? 'dark.textDark' : 'inherit'}`,
    }),
});

export const chakraDatepickerDarkMode = {
    input: {
        color: 'dark.textDark',
        background: 'dark.bgDark',
    },
    '.datepicker-calendar': {
        '.chakra-heading': {
            color: 'dark.textDark',
        },
        'hr + div': {
            div: {
                color: 'dark.textDark',
            },
            button: {
                color: 'dark.textDark',
            },
        },
    },
};

export const chakraDatepicker = {
    input: {
        color: 'black',
    },
    '.datepicker-calendar': {
        '.chakra-heading': {
            color: 'black',
        },
        'hr + div': {
            div: {
                color: 'black',
            },
            button: {
                color: 'black',
            },
        },
    },
};

export const billingNoteTableDarkMode = {
    '& table tbody tr td': {
        color: 'black!important',
    },
};

export const billingDayDarkMode = {
    '& label': {
        color: '#FFFFFFB3',
    },
    '& .MuiSelect-root': {
        color: '#FFFFFFB3',
        width: 50,
        textAlign: 'center',
    },
    '.agreement-checkbox span': {
        color: '#FFFFFFB3',
    },
};

/**
 * @param {boolean} darkMode - from redux
 * @note - add this style inside `sx` prop of MUI elements
 */
export const getInputDarkStyle = (darkMode) => ({
    label: {
        color: darkMode ? '#FFFFFF80' : null,
    },
    '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: darkMode ? '#FFFFFF' : null,
    },
    fieldset: {
        borderColor: darkMode ? '#545454' : null,
        color: darkMode ? '#FFFFFF80' : null,
    },

    '.MuiSelect-nativeInput, input': {
        color: darkMode ? '#FFFFFF' : null,
    },
});

export const getInputDarkImportantStyle = (darkMode) => ({
    '.operator-connect-profiles': {
        'label, .MuiOutlinedInput-input.Mui-disabled': {
            color: darkMode ? '#FFFFFF80!important' : null,
        },

        fieldset: {
            borderColor: darkMode ? '#545454!important' : null,
            color: darkMode ? '#FFFFFF80!important' : null,
        },
    },
});

export const selectedTabStyles = {
    color: 'var(--chakra-colors-brand-500)!important',
    borderBottom: '2px solid var(--chakra-colors-brand-500)!important',
    transition: 'border-bottom 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
};

export const customTabStyles = {
    minW: '160px',
    '&:focus': {
        boxShadow: 'none',
    },
    '&:active': {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        '&::before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'brand.100',
            top: '50%',
            left: '50%',
            width: '120%',
            height: '120%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            animation: 'ripple .5s linear infinite',
        },
        '@keyframes ripple': {
            '0%': {
                width: 0,
                height: 0,
                opacity: 0.5,
            },
            '100%': {
                width: '500px',
                height: '500px',
                opacity: 0,
            },
        },
    },
};

export const selectedTabStylesCombineTheme = {
    ...selectedTabStyles,
    borderBottom: '2px solid var(--chakra-colors-brand-500)!important',
    transition: 'border-bottom 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    opacity: '1',
};

export const customTabStylesCombineTheme = {
    ...customTabStyles,
    backgroundColor: 'transparent',
    opacity: '0.7',
};

/** @type {import('@chakra-ui/react').CSSObject} */
export const chakraTableStyle = {
    '& td[role="gridcell"]': {
        paddingInlineStart: 4,
        py: 2,
        '& > div': {
            alignItems: 'center',
        },
        p: {
            fontSize: 'md',
        },
    },
};

export const scrollBarStyle = (darkMode) => ({
    '::-webkit-scrollbar': {
        width: '9px',
        scrollBehavior: 'smooth',
    },
    '::-webkit-scrollbar-track': {
        background: darkMode ? '#2c2c2c' : '#fafafa',
    },
    '::-webkit-scrollbar-thumb': {
        background: darkMode ? '#6b6b6b' : '#c7c7c7',
        borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: darkMode ? '#515151' : '#a6a6a6',
    },
});

export const customColors = {
    primary: {
        50: '#E5F2FF',
        100: '#CCE5FF',
        200: '#99CAFF',
        300: '#66B0FF',
        400: '#3396FF',
        500: '#007BFF',
        600: '#0063CC',
        700: '#004A99',
        800: '#003166',
        900: '#001933',
    },
    secondary: {
        50: '#F3FDF2',
        100: '#E6FBE5',
        200: '#C0E7B9',
        300: '#99D28C',
        400: '#73BD60',
        500: '#8CC440', // Adjusted to match the existing secondary.500 color
        600: '#418C2C',
        700: '#336E22',
        800: '#255019',
        900: '#18320F',
    },
    tertiary: {
        50: '#f5f0fa',
        100: '#ebe0f5',
        200: '#d1b0e6',
        300: '#b680d6',
        400: '#9c50c7',
        500: '#7750a0',
        600: '#644081',
        700: '#512b61',
        800: '#3e1c41',
        900: '#2b1321',
    },
    gray: {
        50: '#f2f2f2',
        100: '#d9d9d9',
        200: '#bfbfbf',
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#262626',
        900: '#0d0d0d',
    },
    burgundy: {
        50: '#ffe8ea',
        100: '#f1c3c6',
        200: '#e29ca2',
        300: '#d5767d',
        400: '#c85058',
        500: '#af373f',
        600: '#892a31',
        700: '#631d23',
        800: '#3d1014',
        900: '#1c0204',
    },
    mud: {
        50: '#f9f3e5',
        100: '#e4dacc',
        200: '#d0c2af',
        300: '#bba990',
        400: '#a79172',
        500: '#8d7758',
        600: '#6f5d44',
        700: '#4f422f',
        800: '#30281a',
        900: '#160b00',
    },
    brown: {
        50: '#d8cbc4',
        100: '#bca89f',
        200: '#a08679',
        300: '#8b6c5c',
        400: '#765341',
        500: '#6a4a3a',
        600: '#5b3e31',
        700: '#4c3228',
        800: '#3d251e',
        900: '#1e0b00',
    },
    dark: {
        tcap: '#2e2e30',
        'tcap-weak': '#1e1f21',
        bgDark: '#1e2021',
        bgDarkGray: '#3d3e40',
        textDark: '#f5f4f3',
        hoverGray: 'rgba(255, 255, 255, 0.08)',
        'tcap-border': '#2a2b2d',
        'tcap-borderGray': '#565557',
        border: 'rgba(255, 255, 255, 0.12)',
    },
    light: {
        border: 'rgba(0, 0, 0, 0.12)',
        'text-weak': '#6d6e6f',
    },
};

/** @type {import('@chakra-ui/react').ThemeConfig} */
export const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};

export const activeLabelStyles = {
    transform: 'scale(0.85) translateY(-24px)',
};

/** @type {Styles} */
export const styles = {
    global: {
        '.table': {
            border: '1px solid #424242',
            borderCollapse: 'collapse',
        },
        '.tr': {
            display: 'flex',
            width: 'fit-content',
        },
        // '.th, .td': { boxShadow: 'inset 0 0 0 1px #424242' },
        '.th': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'gray.400',
            padding: '0.5rem',
            fontWeight: 'bold',
            fontSize: 'xs',
            textTransform: 'uppercase',
            textAlign: 'center',
        },
        '.td > input': {
            m: '1',
            padding: '0.2rem',
            bg: 'transparent',
            maxW: '100%',
        },
        '.date-wrapper': {
            display: 'flex',
            alignItems: 'center',
            w: '100%',
            h: '100%',
        },
        '.resizer': {
            position: 'absolute',
            opacity: 0,
            top: 0,
            right: 0,
            h: '100%',
            w: '5px',
            bg: '#27bbff',
            cursor: 'col-resize',
            userSelect: 'none',
            touchAction: 'none',
            borderRadius: '6px',
        },
        '.resizer.isResizing': {
            bg: '#2eff31',
            opacity: 1,
        },
        '*:hover > .resizer': {
            opacity: 1,
        },
    },
};
